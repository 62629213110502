import React, { useCallback, useState } from 'react';

import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';

import CFButton from 'components/buttons/CFButton';
import CFPortal, { ContentPosition } from 'components/CFPortal';
import { CFRole } from 'domain/general.types';

import CFScatterPlotChart from './scatterPlot';
import { ScatterProps } from './types';

const ExpandableScatterPlot = (props: ScatterProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleCloseExtendedChart = useCallback(() => {
    setExpanded(false);
  }, []);

  return (
    <>
      {expanded && (
        <CFPortal mode={ContentPosition.Center} onClickOutside={handleCloseExtendedChart}>
          <CFScatterPlotChart
            {...props}
            showBoundingBoxes={true}
            highlightEdgeValues={true}
            showStatLines={true}
            className="expandable-scatter-plot"
            overlapHeader={false}
          />
        </CFPortal>
      )}
      <CFScatterPlotChart {...props}>
        <CFButton
          onClick={() => setExpanded(true)}
          description="Expand"
          value=""
          iconName={faUpRightAndDownLeftFromCenter}
          role={CFRole.OnlyIcon}
        />
        {props.children}
      </CFScatterPlotChart>
    </>
  );
};

export default ExpandableScatterPlot;
