import React from 'react';

import InterventionContext from '../../useContext';
import NudgeAdoptionCreator from './nudge-adoption-creator';
import { useServicesContext } from 'hooks/useServicesContext';

const NudgeAdoption = () => {
  const { nudgeService, traitSessionService: traitService } = useServicesContext();
  return (
    <InterventionContext>
      <NudgeAdoptionCreator nudgeService={nudgeService} traitService={traitService} />
    </InterventionContext>
  );
};

export default NudgeAdoption;
