import { useEffect, useState } from 'react';

import { AlgorithmClass, AppModel } from 'domain/model.types';
import { useServicesContext } from 'hooks/useServicesContext';

const useEmbeddingModels = () => {
  const [models, setModels] = useState<AppModel[]>([]);
  const { modelService } = useServicesContext();

  useEffect(() => {
    (async () => {
      const models = (await modelService.getModels(-1, -1, AlgorithmClass.Embedding)).data;

      setModels(models);
    })();
  }, []);

  return models;
};

export default useEmbeddingModels;
