import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import { ModelId } from 'domain/model.types';
import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';
import CFSelect, { Option } from 'components/CFSelect';
import useEmbeddingModels from 'services/model/hooks/useEmbeddingModels';
import { Steps } from '../../useContext';

export interface EmbeddingModelPickerRef {
  value: () => ModelId;
}

interface Props {}

const EmbeddingModelPicker = forwardRef<EmbeddingModelPickerRef, Props>(function EmbeddingModelPicker({}: Props, ref) {
  const models = useEmbeddingModels();
  const [selectedModel, setSelectedModel] = useState<Option>({ value: '', label: '' });

  useImperativeHandle(ref, () => ({
    value() {
      return selectedModel.value;
    },
  }));

  const modelOptions = useMemo(() => {
    return models.map((model) => ({ value: model.definition.id, label: model.definition.name }));
  }, [models]);

  return (
    <InterventionSection name={Steps.EmbeddingModel} title={'Embedding model'} subtitle="Pick a embedding model">
      <CFSelect
        options={modelOptions}
        isMulti={false}
        value={selectedModel}
        onSelected={(option) => setSelectedModel(option)}
      />
    </InterventionSection>
  );
});

export default EmbeddingModelPicker;
