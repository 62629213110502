import React from 'react';
import dayjs from 'dayjs';

import { Nudge, NudgeType } from 'services/nudging/nudge.types';
import { templateToJSX } from 'services/nudging/templateParsing';
import KeyValue from 'views/intervention/components/KeyValue';

import '../../nudge-common.scss';
import './nudge-detail.scss';

const NudgeDetail = ({ definition, created_at, created_by }: Nudge) => {
  if (!definition) {
    return <div></div>;
  }

  return (
    <div className="nudge-detail">
      {definition.type === NudgeType.Message && (
        <div className="col">
          <KeyValue name="Title" value={definition.message.title} />
          <KeyValue name="Message" value={<>{templateToJSX(definition.message.body)}</>} forceBreakline />
          {definition.message.tmpl_cfg && (
            <KeyValue name="Subject type" value={definition.message.tmpl_cfg.item_pair_cfg.item_type} />
          )}
        </div>
      )}
      {definition.type === NudgeType.Recommender && (
        <div className="col">
          <KeyValue name="Model ID" value={definition.recommend.model_id} />
          <KeyValue name="Subject type" value={definition.recommend.subject_type} />
        </div>
      )}
      <div className="col">
        <KeyValue name="Created at" value={dayjs(created_at).format('YYYY-MM-DD')} />
        <KeyValue name="Created by" value={created_by || ''} />
      </div>
      <div className="col">
        <KeyValue name="" value="" />
      </div>
    </div>
  );
};

export default NudgeDetail;
