import React from 'react';

import classNames from 'classnames';
import CFTitledSection from 'components/CFTitledSection';
import { ExpandableScatterPlot } from 'components/charts/CFScatterPlotChart';

import { CensoringMetricsWrap } from 'domain/model.types';
import { lifetimeDescription } from '../../constants';

interface Props {
  className: string;
  censoringMetrics: CensoringMetricsWrap;
}

import './predicted-observed-lifetime.scss';

const PredictedVSObservedLifetime = ({ className, censoringMetrics }: Props) => {
  return (
    <div className={classNames('predicted-observed-lifetime', 'inrow', className)}>
      <CFTitledSection
        title="Predicted vs observed lifetime plots for churned users"
        className="inrow"
        description={lifetimeDescription}
      >
        <ExpandableScatterPlot
          title={''}
          overlapHeader={true}
          series={[
            {
              name: 'diag',
              values:
                censoringMetrics?.censoring.diag.x.map((value, i) => ({
                  x: value,
                  y: censoringMetrics?.censoring.diag.y[i],
                  id: '',
                })) || [],
            },
          ]}
          xLabel={`${censoringMetrics?.censoring.diag.x_label} (in ${censoringMetrics?.censoring.diag.x_unit}s)`}
          yLabel={`${censoringMetrics?.censoring.diag.y_label} (in ${censoringMetrics?.censoring.diag.y_unit}s)`}
          step={20}
          square={true}
          size={675}
        />

        <ExpandableScatterPlot
          title={''}
          overlapHeader={true}
          series={[
            {
              name: 'horz',
              values:
                censoringMetrics?.censoring.horz.x.map((value, i) => ({
                  x: value,
                  y: censoringMetrics?.censoring.horz.y[i],
                  id: '',
                })) || [],
            },
          ]}
          xLabel={`${censoringMetrics?.censoring.horz.x_label} (in ${censoringMetrics?.censoring.horz.x_unit}s)`}
          yLabel={`${censoringMetrics?.censoring.horz.y_label} (in ${censoringMetrics?.censoring.horz.y_unit}s)`}
          step={20}
          square={true}
          //className={'predicted-observed-scatter'}
          //size={675}
        />
      </CFTitledSection>
    </div>
  );
};

export default PredictedVSObservedLifetime;
