import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'debounce';

import { Trait } from 'domain/traits.types';

import { getDisplayName, getIdentifier } from 'services/traits/helpers.traits';
import { Ptr } from 'services/cohort/cohort.types.api';

import CFSelect, { Option } from 'components/CFSelect';

interface Props {
  traits: Trait[];
  onSelected: (option: Option) => void;
}

const CFTraitSelect = ({ onSelected, traits }: Props) => {
  const [metricsSearch, setMetricsSearch] = useState('');
  const [selectedSupportingTraitName, setSelectedSupportingTraitName] = useState<Ptr>();

  const handleSelectedMetric = useCallback((option: Option) => {
    setSelectedSupportingTraitName(option.value);
    onSelected(option);
  }, []);

  useEffect(() => {
    if (!traits || !traits.length) {
      return;
    }

    setSelectedSupportingTraitName(traits[0].addr.ptr);
  }, [traits]);

  const optionSupportedMetrics = useMemo(() => {
    if (!traits || !traits.length) {
      return { value: '', label: '' };
    }

    let selectedTrait = traits.find((trait) => trait.addr.ptr === selectedSupportingTraitName);

    if (!selectedTrait) {
      selectedTrait = traits[0];
    }

    return {
      value: selectedTrait.addr.ptr,
      label: getDisplayName(selectedTrait, true),
    };
  }, [selectedSupportingTraitName]);

  const handleMetricsSearch = useCallback((text: string) => {
    setMetricsSearch(text);
  }, []);

  const supportingTraitsFiltered = useMemo(() => {
    return traits.filter((trait) => getDisplayName(trait, true).toLowerCase().includes(metricsSearch.toLowerCase()));
  }, [traits, metricsSearch]);

  const DEBOUNCE_SEARCH_TIMEOUT = 200;

  const debouncedUpdateOffset = useCallback(debounce(handleMetricsSearch, DEBOUNCE_SEARCH_TIMEOUT), []);

  return (
    <CFSelect
      options={(supportingTraitsFiltered || []).map((trait) => ({
        value: getIdentifier(trait),
        label: getDisplayName(trait, true),
      }))}
      isMulti={false}
      value={optionSupportedMetrics}
      onSelected={handleSelectedMetric}
      searchable={true}
      onSearch={debouncedUpdateOffset}
    />
  );
};

export default CFTraitSelect;
