import React, { useMemo } from 'react';

import { timeWindowToHuman } from 'helpers/misc';

import { AppModel } from 'domain/model.types';

import KeyValue from 'views/intervention/components/KeyValue';

interface Props {
  model: AppModel;
}

const EmbeddingParamsViewer = ({ model }: Props) => {
  const [timeWindowValue, timeWindowUnit] = useMemo(() => {
    return timeWindowToHuman(model.definition.data_defn.embedding?.look_back_time || 0);
  }, [model]);

  return (
    <>
      <KeyValue name="Look back time" value={`${timeWindowValue} ${timeWindowUnit}`} />
      <KeyValue name="Interaction type" value={`${model.definition.data_defn.embedding?.interaction_type}`} />
    </>
  );
};

export default EmbeddingParamsViewer;
