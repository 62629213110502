import { AxiosError, AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove as httpDelete } from './drivers/http';

import { AdoptedNudgeAPI, AdoptedNudgeCreateRequest, AppAdoptedNudge, NudgeStat } from 'services/nudging/nudge.types';
import Cohort from 'services/cohort/domain/Cohort';

import { PaginatedElement } from 'types';
import { RepoConfig } from './types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/nudge/adopted';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFAdoptedNudgeRepository {
  init: ({ token, oid, pid }: RepoConfig) => void;
  create: (nudge: AdoptedNudgeCreateRequest) => void;
  get: (page: number, per_page: number) => Promise<PaginatedElement<AppAdoptedNudge>>;
  remove: (nudgeId: number) => void;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (page: number, per_page: number): Promise<PaginatedElement<AppAdoptedNudge>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse<PaginatedElement<AdoptedNudgeAPI>>;

    const nudges = data.map((nudgeAPI) => ({ ...nudgeAPI, cohort: new Cohort(nudgeAPI.cohort) }));

    return { total, data: nudges || [] };
  } catch (err) {
    throw new Error((err as AxiosError<any>).response?.data?.message || (err as any).message);
  }
};

export const create = async (adoptedNudge: AdoptedNudgeCreateRequest) => {
  const config = {};

  const body = adoptedNudge;

  try {
    const res = await httpPost(
      `${serverBaseUrl}${path}/create?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    );
    return res;
  } catch (err) {
    throw new Error((err as AxiosError<any>).response?.data?.message || (err as any).message);
  }
};

export const remove = async (nudgeId: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    (await httpDelete(`${serverBaseUrl}${path}/delete/${nudgeId}`, config)) as AxiosResponse;
  } catch (err) {
    throw new Error((err as AxiosError<any>).response?.data?.message || (err as any).message);
  }
};

export const stats = async (adoptionId: number): Promise<NudgeStat> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const data = (await httpGet(
      `${serverBaseUrl}${path}/nudge-stats/${adoptionId}`,
      config
    )) as AxiosResponse<NudgeStat>;

    return data.data;
  } catch (err) {
    throw new Error((err as AxiosError<any>).response?.data?.message || (err as any).message);
  }
};

export default {
  init,
  get,
  remove,
  create,
};
