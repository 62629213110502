import { useEffect, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';
import { InterventionId, InterventionViewExtended } from '../intervention.types';

const useIntervention = (id: InterventionId) => {
  const { interventionService } = useServicesContext();

  const [intervention, setIntervention] = useState<InterventionViewExtended>();

  useEffect(() => {
    (async () => {
      const updatedIntervention = await interventionService.getView(id);

      setIntervention(updatedIntervention);
    })();
  }, [id]);

  return intervention;
};

export default useIntervention;
