import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { CFRole } from 'domain/general.types';
import { TraitSubject } from 'domain/traits.types';

import { preview as previewCohort } from 'services/cohort/cohort.repo';
import { FilterGroupOperation, Leaf, Tree, Node } from 'services/cohort/cohort.types.api';
import Cohort from 'services/cohort/domain/Cohort';
import FilterSet from 'services/cohort/domain/FilterSet';
import Filter from 'services/cohort/domain/Filter';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';
import NewCohortContent from 'views/segmentation/cohorts/NewCohort/new-cohort';

import { SectionAction } from 'components/CFTitledSection';
import CFButton from 'components/buttons/CFButton';
import CFPortal, { ContentPosition } from 'components/CFPortal';
import CFPaginatedList from 'components/CFPaginatedList';
import CFLoadWrapper from 'components/CFLoadWrapper';
import CFModal from 'components/CFModal';
import CFDownloadButton from 'components/buttons/CFDownloadButton';
import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import CFSplitRatios, { CFSplitRatiosRef } from 'components/CFSplitRatios';
import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';

import Sampling from './sampling';

import CohortSelector from 'connected-components/CohortSelector';

import { Steps, useModelContext } from '../useContext';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import { useServicesContext } from 'hooks/useServicesContext';

import './model-cohort.scss';

export enum ModelCohortType {
  Item = 'item',
  User = 'user',
}

export enum SamplingType {
  Mandatory = 'mandatory', // pick users from a sample
  Optional = 'optional', // use all users from cohort or from a sample
  None = 'none', // never show sampling
}

interface Props {
  showSplitRatio?: boolean;
  title: string;
  subtitle: string;
  type: ModelCohortType;
  subjectTypes: TraitSubject[];
  sampling?: SamplingType;
}

enum SamplingVisibilityValues {
  Sampling = 'sampling',
  NoSampling = 'no-sampling',
}

const ModelCohortPicker = ({
  showSplitRatio = true,
  title,
  subtitle,
  subjectTypes,
  type,
  sampling = SamplingType.Optional,
}: Props): JSX.Element => {
  const {
    updateReadiness,
    setUserCohort,
    setItemCohort,
    selectedSubjectType,
    selectedItemSubjectType,
    setSelectedSubjectType,
    setSelectedItemSubjectType,
    definition,
    userSampleId,
    itemSampleId,
  } = useModelContext();
  const { cohortService, traitSessionService: traitService } = useServicesContext();
  const splitRatioRef = useRef<CFSplitRatiosRef>();

  const [newCohortModalOpened, setNewCohortModalOpened] = useState(false);
  const [trainingCohort, setTrainingCohort] = useState<Cohort | null>(null);
  const [trainingCohortExtraFilters, setTrainingCohortExtraFilters] = useState<Leaf<Filter> | undefined>(undefined);

  const [subjects, setSubjects] = useState<string[]>([]);
  const [totalSubjects, setTotalSubjects] = useState(0);

  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(10);

  const [previewReady, setPreviewReady] = useState(false);
  const [samplingVisibility, setSamplingVisibility] = useState(false);

  const samplingIsActive = useMemo(() => {
    return samplingVisibility || sampling === SamplingType.Mandatory;
  }, [samplingVisibility, definition?.class]);

  const subject = useMemo(() => {
    return type === ModelCohortType.Item ? selectedItemSubjectType : selectedSubjectType;
  }, [type, selectedItemSubjectType, selectedSubjectType]);

  const stepName = useMemo(() => {
    return type === ModelCohortType.User ? Steps.UserCohort : Steps.ItemCohort;
  }, [type]);

  const setCohort = useMemo(() => {
    return type === ModelCohortType.User ? setUserCohort : setItemCohort;
  }, [type]);

  const sampleId = useMemo(() => {
    return type === ModelCohortType.User ? userSampleId : itemSampleId;
  }, [userSampleId, itemSampleId]);

  const setSubjectType = useMemo(() => {
    return type === ModelCohortType.User ? setSelectedSubjectType : setSelectedItemSubjectType;
  }, [type]);

  const handleCohortSelected = useCallback((cohort: Cohort) => {
    setTrainingCohort(cohort);
  }, []);

  const handleCohortExtraFiltersSelected = useCallback((filterGroup: Leaf<Filter>) => {
    setTrainingCohortExtraFilters(filterGroup);
  }, []);

  useEffect(() => {
    const valid = validateCohorts();

    if (!valid) {
      updateReadiness(valid, stepName);

      return;
    }

    setCohort({
      type: samplingIsActive ? 'sample' : 'cohort',
      cohort: {
        cohort_id: (trainingCohort as Cohort).id,
        extra_filters: trainingCohortExtraFilters?.filters || [],
      },
      split_ratio: showSplitRatio ? splitRatioRef.current?.value() : undefined,
      sample_id: samplingIsActive ? sampleId : undefined,
    });

    updateReadiness(valid, stepName);
  }, [trainingCohort, trainingCohortExtraFilters, splitRatioRef, sampleId, samplingIsActive]);

  const validateCohorts = () => {
    if (samplingIsActive && !sampleId) {
      return false;
    }

    return Boolean(trainingCohort !== null);
  };

  const loadPreviewForTab = async () => {
    if (!validateCohorts()) {
      return;
    }

    const nodes: Node[] = [];

    if (trainingCohort) {
      nodes.push({ tree: trainingCohort.tree.getLegacy() });
    }

    if (trainingCohortExtraFilters) {
      nodes.push({ leaf: trainingCohortExtraFilters });
    }

    const newTree: Tree = {
      op: FilterGroupOperation.And,
      nodes,
    };

    setPreviewReady(false);

    const { total, data } = await previewCohort(new FilterSet(newTree), curPage, curPageSize);

    setSubjects(data);
    setTotalSubjects(total);
    setPreviewReady(true);
  };

  useEffect(() => {
    loadPreviewForTab();
  }, [curPage, curPageSize, trainingCohort, trainingCohortExtraFilters]);

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleSamplingVisibility = useCallback((option: ButtonGroupOption) => {
    setSamplingVisibility(option.value === SamplingVisibilityValues.Sampling);
  }, []);

  const handleSelectedSubjectType = useCallback(
    (option: Option) => {
      setSubjectType(option.value as TraitSubject);

      setSubjects([]);
      setTotalSubjects(0);
      setPreviewReady(true);
    },
    [type]
  );

  const [previewModal, setPreviewModal] = useState(false);

  return (
    <div className="model-cohort">
      <InterventionSection name={stepName} title={title} subtitle={subtitle}>
        <SectionAction>
          <CFButton
            value="Create Cohort"
            iconCustom={<img src={pagePlusIcon} />}
            role={CFRole.Primary}
            onClick={() => setNewCohortModalOpened(true)}
          />
        </SectionAction>

        <div className="model-cohort-tab">
          {subjectTypes.length > 1 && (
            <CFTitledComponent title="Select item type" className="constrained-width">
              <CFSelect
                isMulti={false}
                options={subjectTypes.map((type) => ({ label: type, value: type }))}
                value={{ label: subject, value: subject }}
                onSelected={handleSelectedSubjectType}
              />
            </CFTitledComponent>
          )}

          <CohortSelector
            traitService={traitService}
            onCohortSelected={handleCohortSelected}
            onExtraFilterUpdated={handleCohortExtraFiltersSelected}
            subject={subject}
          />
          {showSplitRatio && <CFSplitRatios ref={(el) => (splitRatioRef.current = el as CFSplitRatiosRef)} />}

          <CFLoadWrapper isLoading={!previewReady && validateCohorts()} spinnerSize={30}>
            <div className="preview-cohort">
              <div className="preview-cohort-title">Results ({totalSubjects})</div>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                size="lg"
                className="preview-cohort-icon"
                onClick={() => setPreviewModal(previewReady)}
              />
            </div>
          </CFLoadWrapper>

          {sampling === SamplingType.Optional && (
            <CFButtonGroup
              options={[
                { label: 'Sample', value: SamplingVisibilityValues.Sampling },
                { label: 'No sample', value: SamplingVisibilityValues.NoSampling },
              ]}
              value={
                samplingVisibility
                  ? { label: 'Sample', value: SamplingVisibilityValues.Sampling }
                  : { label: 'No sample', value: SamplingVisibilityValues.NoSampling }
              }
              onSelect={handleSamplingVisibility}
            />
          )}

          {samplingIsActive && (
            <Sampling
              cohort={trainingCohort}
              cohortService={cohortService}
              extraFilter={trainingCohortExtraFilters}
              totalSubjects={totalSubjects}
              isLoading={false}
              type={type}
            />
          )}
        </div>

        <div>
          {newCohortModalOpened && (
            <CFPortal onClickOutside={() => setNewCohortModalOpened(false)} mode={ContentPosition.Center}>
              <div className="model-cohort-modal new-cohort">
                <NewCohortContent traitService={traitService} />
              </div>
            </CFPortal>
          )}
        </div>

        <>
          {previewModal && (
            <CFModal className="preview-modal" onClose={() => setPreviewModal(false)}>
              <div className="preview-modal-title">Results ({totalSubjects})</div>
              <div className="preview-modal-download">
                <CFDownloadButton
                  getData={() => subjects}
                  disabled={totalSubjects === 0 || !previewReady}
                  name={`model-subjects.csv`}
                />
              </div>
              <CFPaginatedList total={totalSubjects} onPageChange={handleNewPageRequest}>
                <CFLoadWrapper isLoading={!previewReady}>
                  <div className="preview-items">
                    {subjects.map((subject) => (
                      <div className="preview-item" key={subject}>
                        {subject}
                      </div>
                    ))}
                  </div>
                </CFLoadWrapper>
              </CFPaginatedList>
            </CFModal>
          )}
        </>
      </InterventionSection>
    </div>
  );
};

export default ModelCohortPicker;
