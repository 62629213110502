import React from 'react';

import Schedule from 'connected-components/schedule';
import { Model } from 'domain/model.types';
import Cohort from 'services/cohort/domain/Cohort';
import ModelCohort from '../components/cohort';
import ModelFeatures from '../components/features';
import ModelRuns from '../components/runs';
import ModelTarget from '../components/target';
import CFTitledSection from 'components/CFTitledSection';
import ModelDefinitionView from '../components/definition';
import Wizard from 'components/CFWizard';
import { Steps } from 'views/model/creation/useContext';

interface Props {
  model: Model;
}

const ModelBanditDefinition = ({ model }: Props) => {
  return (
    <Wizard>
      <Wizard.Step name={Steps.Definition} ready={true}>
        <ModelDefinitionView />
      </Wizard.Step>

      <Wizard.Step name={Steps.UserCohort} ready={true}>
        <CFTitledSection title="Cohort" id={Steps.UserCohort}>
          <ModelCohort
            model={model}
            cohort={new Cohort(model.cohort)}
            splitRatio={model.definition.population_policy.split_ratio}
          />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Features} ready={true}>
        <CFTitledSection title="Features" id={Steps.Features}>
          <ModelFeatures model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Target} ready={true}>
        <CFTitledSection title="Target" id={Steps.Target}>
          <ModelTarget model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Runs} ready={true}>
        <CFTitledSection title="Runs" id={Steps.Runs}>
          <ModelRuns model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Schedule id={model.schedule.id} label="model" />
    </Wizard>
  );
};

export default ModelBanditDefinition;
