import { DataType, Operators } from 'domain/general.types';

const commonOperators = [Operators.Equal, Operators.Is, Operators.IsNot];

const OperatorForNumbers = [
  ...commonOperators,
  Operators.NotEqual,
  Operators.HigherThan,
  Operators.HigherOrEqualThan,
  Operators.LessThan,
  Operators.LessOrEqualThan,
];

const OperatorForTimestamps = [
  ...commonOperators,
  Operators.NotEqual,
  Operators.HigherThan,
  Operators.HigherOrEqualThan,
  Operators.LessThan,
  Operators.LessOrEqualThan,
];

const OperatorForBool = [...commonOperators, Operators.NotEqual];

const OperatorForString = [...commonOperators, Operators.NotEqual, Operators.IncludedIn, Operators.NotIncludedIn];

const operatorsPerType = {
  [DataType.Boolean]: OperatorForBool,
  [DataType.Bool]: OperatorForBool,
  [DataType.Number]: OperatorForNumbers,
  [DataType.Float4]: OperatorForNumbers,
  [DataType.Int4]: OperatorForNumbers,
  [DataType.Int2]: OperatorForNumbers,
  [DataType.Varchar]: OperatorForString,
  [DataType.VarcharArray]: commonOperators,
  [DataType.Timestamp]: OperatorForTimestamps,
};

export const getOperators = (type: DataType) => {
  return operatorsPerType[type] || commonOperators;
};
