import React, { useState } from 'react';

import Wizard from 'components/CFWizard';

import SchedulingBuilder from 'connected-components/SchedulingBuilder';

import InterventionSection from './interventionSection';
import { InterventionBanditMetricsPolicy } from './metricsPolicy';
import InterventionNudgePolicy from './nudgePolicy';
import InterventionParticipantsBandit from './participants/participants-bandit';

import { InterventionViewExtended, NewInterventionRequest } from 'services/intervention/intervention.types';

import { Steps } from '.';

import { useInterventionContext } from 'views/intervention/useContext';

import { useServicesContext } from 'hooks/useServicesContext';
import { CFNavList } from 'components/CFNavList';
import { Tabs, allowedTabs, interventionByTab } from 'views/intervention';
import useCFNavigation from 'hooks/useCFNavigation';
import InterventionDefinition from './definition';
import CFButton from 'components/buttons/CFButton';
import { CFRole } from 'domain/general.types';
import { ToastType } from 'components/CFToast/types';
import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import pagePlusIcon from 'assets/icons/pagePlus.svg';
import { Granularity } from 'helpers/dates';
import { ScheduleType } from 'services/scheduling/schedulting.types.api';

interface Props {
  sourceIntervention?: InterventionViewExtended;
  updateReadiness: (isReady: boolean, step: Steps) => void;
}

const NewContextBanditIntervention = ({ sourceIntervention, updateReadiness }: Props) => {
  const {
    cohortService,
    nudgeService,
    traitSessionService: traitService,
    modelService,
    interventionService,
  } = useServicesContext();
  const [loading, setLoading] = useState(false);

  const navigate = useCFNavigation();
  const { addToast } = useToast();

  const {
    sampleId,
    subject,
    definitionRef,
    participantsRef,
    nudgePolicyRef,
    metricsPolicyRef,
    schedulingRef,
    readiness,
    isMicrobatching,
  } = useInterventionContext();

  const buildNewInterventionObject = (): NewInterventionRequest => {
    const { name, description, algorithm } = definitionRef.current.value();

    const request: NewInterventionRequest = {
      intervention: {
        name,
        description,
        participant_policy: participantsRef.current?.value().participants,
        nudge_policy: nudgePolicyRef.current?.value(),
        metric_policy: metricsPolicyRef.current?.value(),
        algo_policy: { ...algorithm, split_ratio: participantsRef.current?.value().splitRatio },
      },
      schedule: schedulingRef.current?.value(),
    };

    return request;
  };

  const handleCreateIntervention = async () => {
    const newInterventionRequest = buildNewInterventionObject();

    try {
      setLoading(true);
      await interventionService.create(newInterventionRequest.intervention, newInterventionRequest.schedule);

      addToast('Intervention created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.intervention);
    } catch (err) {
      addToast(`Impossible to create intervention ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating intervention: ', err);
    }
  };

  return (
    <div className="intervention">
      <Wizard>
        <Wizard.Header>
          <CFNavList
            titles={allowedTabs()}
            selected={Tabs.Interventions}
            onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
          />
        </Wizard.Header>

        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <InterventionDefinition
            ref={definitionRef}
            modelService={modelService}
            defaultValue={
              sourceIntervention && {
                name: sourceIntervention.intervention.name,
                description: sourceIntervention.intervention.description,
                algorithm: sourceIntervention.intervention.algo_policy,
              }
            }
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Definition)}
          />
        </Wizard.Step>
        <Wizard.Step name={Steps.Nudge} ready={readiness.nudge}>
          <InterventionNudgePolicy
            nudgeService={nudgeService}
            traitService={traitService}
            defaultValue={sourceIntervention && sourceIntervention.intervention.nudge_policy}
            ref={nudgePolicyRef}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Nudge)}
          />
        </Wizard.Step>
        <Wizard.Step name={Steps.Participants} ready={readiness.participants}>
          <InterventionParticipantsBandit
            ref={participantsRef}
            traitService={traitService}
            cohortService={cohortService}
            defaultValue={
              sourceIntervention && {
                cohort: sourceIntervention.cohort,
                extra_filters: sourceIntervention.intervention.participant_policy.static_sample.extra_filters,
                splitRatio: sourceIntervention.intervention.algo_policy.split_ratio,
                sampleCount: sourceIntervention.sampleSize || 0,
              }
            }
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Participants)}
          />
        </Wizard.Step>
        <Wizard.Step name={Steps.Metrics} ready={readiness.metrics}>
          <InterventionBanditMetricsPolicy
            ref={metricsPolicyRef}
            sampleId={sampleId}
            subject={subject}
            defaultValue={sourceIntervention && sourceIntervention.intervention.metric_policy}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Metrics)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Scheduling} ready={readiness.scheduling}>
          <InterventionSection
            name={Steps.Scheduling}
            title="Scheduling"
            subtitle="Set schedules for your intervention."
          >
            <SchedulingBuilder
              defaultValue={sourceIntervention?.schedule}
              ref={schedulingRef}
              forcedGranularity={isMicrobatching ? Granularity.Hour : undefined}
              availableTypes={isMicrobatching ? [ScheduleType.Recurring] : undefined}
              onReady={(thisReady) => updateReadiness(thisReady, Steps.Scheduling)}
            />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Intervention"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateIntervention}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default NewContextBanditIntervention;
