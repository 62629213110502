import React from 'react';

import { Model } from 'domain/model.types';
import { ColAddr } from 'domain/traits.types';

import { getIdentifierFromAddr, getTraitNameFromAddr } from 'services/traits/helpers.traits';
import Cohort from 'services/cohort/domain/Cohort';

import useCohort from 'views/segmentation/cohorts/hooks/useCohort';

import CFTraitItem from 'connected-components/traits/CFTraitItem';
import Schedule from 'connected-components/schedule';

import ModelCohort from '../components/cohort';
import ModelTarget from '../components/target';
import ModelRuns from '../components/runs';
import ModelParameters from '../components/parameters';

import { getDurationFromNanos, Granularity } from 'helpers/dates';
import CFTitledSection from 'components/CFTitledSection';
import ModelDefinitionView from '../components/definition';
import Wizard from 'components/CFWizard';
import { Steps } from 'views/model/creation/useContext';
import KeyValue from 'views/intervention/components/KeyValue';
import CFTitledComponent from 'components/CFTitledComponent';

import './model-recommender-details.scss';

interface Props {
  model: Model;
}

const DynamicFeatures = ({ addrs }: { addrs: ColAddr[] }) => {
  return (
    <CFTitledComponent title="Dynamic features">
      <div className="list-of-traits">
        {addrs.map((addr) => (
          <CFTraitItem key={getIdentifierFromAddr(addr)} addr={addr} />
        ))}
      </div>
    </CFTitledComponent>
  );
};

const ModelRecommenderDetails = ({ model }: Props) => {
  const userCohort = useCohort({ id: `${model.definition?.data_defn?.recommender?.user_cohort_id}` });
  const staticFeatures = model.definition.data_defn.static_features || [];

  return (
    <Wizard>
      <Wizard.Step name={Steps.Definition} ready={true}>
        <ModelDefinitionView />
      </Wizard.Step>

      <Wizard.Step name={Steps.Parameters} ready={true}>
        <CFTitledSection title="Parameters" id={Steps.Parameters}>
          <ModelParameters model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.UserCohort} ready={true}>
        <CFTitledSection title="User Cohort" id={Steps.UserCohort}>
          <ModelCohort model={model} cohort={userCohort} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.ItemCohort} ready={true}>
        <CFTitledSection title="Item Cohort" id={Steps.ItemCohort}>
          <ModelCohort
            model={model}
            cohort={new Cohort(model.cohort)}
            splitRatio={model.definition.population_policy.split_ratio}
          />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Features} ready={true}>
        <CFTitledSection title="Features" id={Steps.Features}>
          <>
            <DynamicFeatures addrs={model.definition.data_defn.recommender?.embedding_traits as ColAddr[]} />

            <KeyValue
              name="Test duration"
              value={`${getDurationFromNanos(
                model.definition.data_defn.recommender?.test_dur || 0,
                Granularity.Day
              )} days`}
            />

            <KeyValue
              name="Full duration"
              value={`${getDurationFromNanos(
                model.definition.data_defn.recommender?.full_dur || 0,
                Granularity.Day
              )} days`}
            />

            {staticFeatures.length !== 0 && (
              <div className="model-features-selected">
                <div className="title">Static Features</div>
                <div className="selected-items">
                  {staticFeatures.map((addr) => (
                    <CFTraitItem key={getTraitNameFromAddr(addr)} addr={addr} />
                  ))}
                </div>
              </div>
            )}
          </>
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Target} ready={true}>
        <CFTitledSection title="Target" id={Steps.Target}>
          <ModelTarget model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Runs} ready={true}>
        <CFTitledSection title="Runs" id={Steps.Runs}>
          <ModelRuns model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Schedule} ready={true}>
        <Schedule id={model.schedule.id} label="model" />
      </Wizard.Step>
    </Wizard>
  );
};

export default ModelRecommenderDetails;
