import React, { useEffect, useState } from 'react';

import { CFNavList } from 'components/CFNavList';
import useCFNavigation from 'hooks/useCFNavigation';
import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';
import { useParams } from 'react-router-dom';
import { stats } from 'repositories/adopted_nudges';
import { NudgeStat } from 'services/nudging/nudge.types';
import NudgeStats from 'views/intervention/components/nudges/NudgeStats';
import CFTitledSection from 'components/CFTitledSection';

const NudgeAdoptionDetails = () => {
  const navigate = useCFNavigation();
  const params = useParams();
  const [nudgeStats, setNudgeStats] = useState<NudgeStat>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!params.id) {
        return;
      }

      const nudgeStats = await stats(Number(params.id));
      setIsLoading(false);

      setNudgeStats(nudgeStats);
    })();
  }, [params?.id]);

  return (
    <div>
      <CFNavList
        titles={allowedTabs()}
        selected={Tabs.Adoption}
        onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
      />
      <CFTitledSection title={'Nudge stats'} underlined={true}>
        <NudgeStats stats={nudgeStats} isLoading={isLoading} />
      </CFTitledSection>
    </div>
  );
};

export default NudgeAdoptionDetails;
