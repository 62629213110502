import React, { useCallback, useMemo, useRef, useState } from 'react';

import useCFNavigation from 'hooks/useCFNavigation';
import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import { CFRole } from 'domain/general.types';
import { TraitSubject } from 'domain/traits.types';

import { create as createAdoptedNudge } from 'repositories/adopted_nudges';

import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';
import InterventionNudgePolicy, {
  InterventionNudgePolicyRef,
} from 'views/intervention/interventions/NewIntervention/nudgePolicy';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import Cohort from 'services/cohort/domain/Cohort';
import TraitService from 'services/traits/traitSession.service';
import { FilterGroupOperation } from 'services/cohort/cohort.types.api';
import NudgeService from 'services/nudging/nudge.service';
import { AdoptedNudgeCreateRequest } from 'services/nudging/nudge.types';

import InterventionContext, { useInterventionContext } from '../../useContext';

import CohortSelector from 'connected-components/CohortSelector';
import SchedulingBuilder, { SchedulingBuilderRef } from 'connected-components/SchedulingBuilder';

import { CFNavList } from 'components/CFNavList';
import Wizard from 'components/CFWizard';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';
import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import CFTitledComponent from 'components/CFTitledComponent';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import './nudge-adoption.scss';

interface Props {
  nudgeService: NudgeService;
  traitService: TraitService;
}

export enum Steps {
  Participants = 'participants',
  Nudge = 'nudge',
  Scheduling = 'scheduling',
}

const subjectOptions = [
  { label: 'User', value: TraitSubject.User },
  { label: 'Device', value: TraitSubject.Device },
];

const NudgeAdoptionCreator = ({ nudgeService, traitService }: Props) => {
  const navigate = useCFNavigation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(TraitSubject.User);

  const nudgePolicyRef = useRef<InterventionNudgePolicyRef>() as React.MutableRefObject<InterventionNudgePolicyRef>;
  const schedulingRef = useRef<SchedulingBuilderRef>() as React.MutableRefObject<SchedulingBuilderRef>;

  const { setCohort, setSubject, cohort } = useInterventionContext();

  const [readiness, setReadiness] = useState({
    [Steps.Participants]: false,
    [Steps.Nudge]: false,
    [Steps.Scheduling]: false,
  });

  const updateReadiness = useCallback(
    (isReady: boolean, step: Steps) => {
      setReadiness((readiness) => ({ ...readiness, [step]: isReady }));
    },
    [readiness]
  );

  const handleAdoptNudge = useCallback(async () => {
    const request: AdoptedNudgeCreateRequest = {
      cohort_id: (cohort as Cohort).id,
      nudge_id: Object.values(nudgePolicyRef.current.value().group_allocation)[0],
      expire_dur: nudgePolicyRef.current.value().expire_dur,
      schedule: schedulingRef.current.value(),
    };

    try {
      setLoading(true);
      await createAdoptedNudge(request);

      addToast('Nudge adopted', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.intervention_nudge_adoption);
    } catch (err) {
      addToast(`Impossible to create intervention ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating intervention: ', err);
    }
  }, [cohort]);

  const handleOnCohortSelected = useCallback((cohort: Cohort) => {
    setCohort(cohort);
    setSubject(cohort.subject_type as TraitSubject);
    updateReadiness(true, Steps.Participants);
  }, []);

  const selectedSubjectOption = useMemo(() => {
    return subjectOptions.find((option) => option.value === selectedSubject);
  }, [selectedSubject]);

  return (
    <div className="nudge-adoption">
      <InterventionContext>
        <Wizard>
          <Wizard.Header>
            <CFNavList
              titles={allowedTabs()}
              selected={Tabs.Adoption}
              onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
            />
          </Wizard.Header>

          <Wizard.Step name={Steps.Participants} ready={readiness.participants}>
            <InterventionSection name={Steps.Participants} title={'Participants'}>
              <div className="column">
                <CFTitledComponent title={'Trait subject'}>
                  <CFButtonGroup
                    options={subjectOptions}
                    value={selectedSubjectOption as ButtonGroupOption}
                    onSelect={(option) => setSelectedSubject(option.value)}
                  />
                </CFTitledComponent>

                <CohortSelector
                  traitService={traitService}
                  onCohortSelected={handleOnCohortSelected}
                  onExtraFilterUpdated={() => ({})}
                  subject={selectedSubject}
                  defaultFilters={{
                    filters: [],
                    op: FilterGroupOperation.And,
                  }}
                />
              </div>
            </InterventionSection>
          </Wizard.Step>

          <Wizard.Step name={Steps.Nudge} ready={readiness.nudge}>
            <InterventionNudgePolicy
              ref={nudgePolicyRef}
              nudgeService={nudgeService}
              traitService={traitService}
              minNumberOfNudges={1}
              maxNumberOfNudges={1}
              includeDefaultNudge={false}
              onReady={(thisReady) => updateReadiness(thisReady, Steps.Nudge)}
            />
          </Wizard.Step>

          <Wizard.Step name={Steps.Scheduling} ready={readiness.scheduling}>
            <InterventionSection
              name={Steps.Scheduling}
              title="Scheduling"
              subtitle="Set schedules for your nudge adoption."
            >
              <SchedulingBuilder
                ref={schedulingRef}
                onReady={(thisReady) => updateReadiness(thisReady, Steps.Scheduling)}
              />
            </InterventionSection>
          </Wizard.Step>

          <Wizard.Footer>
            <div className="control">
              <CFButton
                value="Create Nudge Adoption"
                role={CFRole.Primary}
                disabled={Object.values(readiness).some((ready) => !ready)}
                isLoading={loading}
                onClick={handleAdoptNudge}
                iconCustom={<img src={pagePlusIcon} />}
              />
            </div>
          </Wizard.Footer>
        </Wizard>
      </InterventionContext>
    </div>
  );
};

export default NudgeAdoptionCreator;
