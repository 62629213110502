import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';

import TraitService from 'services/traits/traitSession.service';
import { FilterGroupOperation, Leaf } from 'services/cohort/cohort.types.api';
import Cohort from 'services/cohort/domain/Cohort';
import Filter from 'services/cohort/domain/Filter';
import useCohortList from 'services/cohort/hooks/useCohortList';

import LeafBuilder, { LeafRef } from 'connected-components/LeafBuilder';
import TreeViewer from 'connected-components/TreeViewer';

import CFTitledSection from 'components/CFTitledSection';
import CFTitledComponent from 'components/CFTitledComponent';
import CFSelect, { Option } from 'components/CFSelect';
import CohortItem from './components/Item';

import './cohort-selector.scss';

interface Props {
  traitService: TraitService;
  onCohortSelected: (cohort: Cohort) => void;
  onExtraFilterUpdated: (filter: Leaf<Filter>) => void;
  subject?: TraitSubject;
  defaultCohort?: Cohort;
  defaultFilters?: Leaf;
}

const CohortSelector = ({
  traitService,
  onCohortSelected,
  onExtraFilterUpdated,
  subject = TraitSubject.User,
  defaultCohort,
  defaultFilters,
}: Props) => {
  const [traits, setTraits] = useState<Trait[]>([]);
  const [cohort, setCohort] = useState<Cohort>();
  const filterGroupRef = useRef<LeafRef>() as React.MutableRefObject<LeafRef>;

  const listOfCohorts = useCohortList(subject);

  useEffect(() => {
    setCohort(undefined);
  }, [subject]);

  useEffect(() => {
    if (!filterGroupRef || !filterGroupRef.current) {
      return;
    }
    if (defaultFilters) {
      onExtraFilterUpdated(filterGroupRef.current.value());
    }
  }, [cohort, defaultFilters, filterGroupRef]);

  useEffect(() => {
    setTimeout(() => {
      if (!filterGroupRef || !filterGroupRef.current) {
        return;
      }
      onExtraFilterUpdated(filterGroupRef.current.value());
    }, 30);
  }, [defaultFilters, filterGroupRef]);

  useEffect(() => {
    if (!defaultCohort) {
      return;
    }

    setCohort(defaultCohort);
  }, [defaultCohort]);

  useEffect(() => {
    (async () => {
      if (!cohort) {
        return;
      }

      const filterTraits = await traitService.getTraits({
        subject: cohort.subject_type as TraitSubject,
        usage: TraitUsage.Filter,
      });

      setTraits(filterTraits);
    })();
  }, [cohort]);

  const handleFilterGroupChange = useCallback(() => {
    setTimeout(() => {
      if (!filterGroupRef.current) {
        return;
      }

      onExtraFilterUpdated(filterGroupRef.current.value());
    }, 30);
  }, [filterGroupRef]);

  const handleCohortSelected = async (option: Option) => {
    const cohort = listOfCohorts.find((cohort) => cohort.id === parseInt(option.value));

    if (!cohort) {
      return;
    }

    setCohort(cohort);

    onCohortSelected(cohort);
  };

  const itemUnit = useMemo(() => {
    return subject === TraitSubject.User || subject === TraitSubject.Patient ? 'user' : 'item';
  }, [subject]);

  return (
    <div>
      <div className="selected-cohort">
        <CFTitledComponent title={'Select Cohort'} className="selected-cohort__list">
          <CFSelect
            options={
              listOfCohorts?.map((cohort) => ({
                label: cohort.name,
                value: `${cohort.id}`,
                tags: [cohort.subject_type],
                meta: {
                  size: cohort.size,
                  description: cohort.description,
                  unit: itemUnit,
                },
              })) || []
            }
            value={{ label: cohort?.name || '', value: `${cohort?.id}` }}
            onSelected={handleCohortSelected}
            Item={CohortItem}
          />
        </CFTitledComponent>

        <CFTitledComponent title="Description" className="selected-cohort__description">
          <span>{cohort?.description || '-'}</span>
        </CFTitledComponent>
      </div>

      {cohort && (
        <>
          <TreeViewer value={cohort?.tree} />

          <CFTitledSection title="Extra filter">
            <LeafBuilder
              allowAllModels={true}
              subject={cohort.subject_type as TraitSubject}
              ref={filterGroupRef}
              fixedOperation={FilterGroupOperation.And}
              traits={traits}
              onLeafChange={handleFilterGroupChange}
              defaultFilters={defaultFilters}
            />
          </CFTitledSection>
        </>
      )}
    </div>
  );
};

export default CohortSelector;
