import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPause, faPlus, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import CFButton from 'components/buttons/CFButton';
import { Column, ColumnType } from 'components/CFTable';
import CFDataTable from 'components/CFDataTable';
import StatusTag, { StatusTagVariant } from 'components/StatusTag';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTrashButton from 'components/buttons/CFTrashButton';
import { CFNavList } from 'components/CFNavList';
import InterventionDetail from '../../components/InterventionDetail';
import DraftTable from './DraftTable';

import { CFRole } from 'domain/general.types';
import {
  AlgorithmType,
  InterventionId,
  InterventionStatus,
  InterventionViewExtended,
} from 'services/intervention/intervention.types';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { useServicesContext } from 'hooks/useServicesContext';

import { remove as removeIntervention } from 'services/intervention/intervention.repo';

import { allowedTabs, interventionByTab, Tabs } from '../..';
import { displayDate } from 'helpers/dates';

import CFLoadWrapper from 'components/CFLoadWrapper';
import CFDownloadButton from 'components/buttons/CFDownloadButton';
import CFTooltip from 'components/CFTooltip';
import CFCloneButton from 'components/buttons/CFCloneButton';
import { ToastType } from 'components/CFToast/types';

import useCFNavigation from 'hooks/useCFNavigation';

import './list-of-interventions.scss';

const ListOfInterventions = () => {
  const { addToast } = useToast();
  const { interventionService } = useServicesContext();
  const navigate = useCFNavigation();

  const [interventions, setInterventions] = useState<InterventionViewExtended[]>([]);
  const [totalInterventions, setTotalInterventions] = useState(-1);
  const [curPage, setCurPage] = useState(0);
  const [curPageSize, setCurPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const downloadPage = useCallback(async () => {
    setIsLoading(true);
    try {
      const interventions = await interventionService.list(curPage, curPageSize);
      const interventionsExtended = await Promise.all(
        interventions.data.map((view) => interventionService.getView(view.intervention.id))
      );

      setTotalInterventions(interventions.total);
      setInterventions(interventionsExtended);
    } finally {
      setIsLoading(false);
    }
  }, [interventionService, curPage, curPageSize]);

  useEffect(() => {
    downloadPage();
  }, [downloadPage]);

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleDownload = async (id: InterventionId) => {
    const participants = await interventionService.downloadParticipants(id);
    return participants.split('\n');
  };

  const columns: Column[] = useMemo(
    () => [
      {
        title: 'ID',
        field: 'intervention.id',
        type: ColumnType.STRING,
      },
      {
        title: 'Name',
        field: '',
        type: ColumnType.STRING,
        renderCell: (row) => {
          return (
            <div className="intv-title">
              {row.intervention.name}
              {row.failed && (
                <CFTooltip description={row.failedMessage}>
                  <FontAwesomeIcon className="list-of-interventions-error-icon" icon={icon({ name: 'warning' })} />
                </CFTooltip>
              )}
            </div>
          );
        },
        style: {
          // minWidth: '250px',
        },
      },
      {
        title: 'Type',
        field: '',
        type: ColumnType.STRING,
        renderCell: (row) => {
          const rowWithType = row as InterventionViewExtended;
          const algoTypeMap = {
            [AlgorithmType.ABTest]: 'AB',
            [AlgorithmType.Bandit]: 'Bandit',
            [AlgorithmType.RestlessBandit]: 'Restless bandit',
          };

          if (rowWithType.intervention.algo_policy?.type) {
            return algoTypeMap[rowWithType.intervention.algo_policy?.type];
          }

          return rowWithType.intervention.algo_policy?.spec?.algo_name ?? '';
        },
        style: {
          minWidth: '75px',
        },
      },
      {
        title: 'Sample size',
        field: 'sampleSize',
        type: ColumnType.STRING,
        style: {
          minWidth: '75px',
        },
      },
      {
        title: 'Cohort',
        field: 'cohort.name',
        type: ColumnType.STRING,
        style: {
          minWidth: '75px',
        },
      },
      {
        title: 'Created at',
        field: 'ts',
        type: ColumnType.OBJECT,
        renderCell: (row) => {
          return displayDate(new Date(row.intervention.created_at), '00:00', false, false, '');
        },
      },
      {
        title: 'Status',
        field: '',
        type: ColumnType.STRING,
        renderCell: (row) => {
          const statusVariantMap = {
            [InterventionStatus.Finished]: StatusTagVariant.Success,
            [InterventionStatus.Running]: StatusTagVariant.InProgress,
            [InterventionStatus.Terminated]: StatusTagVariant.Terminated,
            [InterventionStatus.Paused]: StatusTagVariant.Paused,
            [InterventionStatus.Pending]: StatusTagVariant.Pending,
          };

          return <StatusTag label={row.status} variant={statusVariantMap[row.status as InterventionStatus]} />;
        },
      },
      {
        title: '',
        field: '',
        type: ColumnType.OBJECT,
        expandable: true,
        renderCell: (row) => (
          <div>
            <div className="list-of-interventions-extended-controls">
              <CFButton
                value="View Details"
                iconName={faEye}
                onClick={() => handleViewMonitoring(row.intervention.id)}
              />
              <CFDownloadButton
                value={'Participants'}
                getData={() => handleDownload(row.intervention.id)}
                disabled={false}
                name={`intervention-${row.id}-assignment.csv`}
              />

              {isAllowedTo(AuthAction.CreateIntervention) && row.status === InterventionStatus.Paused && (
                <CFConfirmableButton
                  title={'Resume intervention'}
                  question={'Are you sure to resume this intervention?'}
                >
                  <CFButton value="Resume" iconName={faPlay} onClick={() => handlePause(row.intervention.id)} />
                </CFConfirmableButton>
              )}

              {isAllowedTo(AuthAction.CreateIntervention) && row.status === InterventionStatus.Running && (
                <CFConfirmableButton title={'Pause intervention'} question={'Are you sure to pause this intervention?'}>
                  <CFButton value="Pause" iconName={faPause} onClick={() => handlePause(row.intervention.id)} />
                </CFConfirmableButton>
              )}

              {isAllowedTo(AuthAction.CreateIntervention) &&
                (row.status === InterventionStatus.Running ||
                  row.status === InterventionStatus.Paused ||
                  row.status === InterventionStatus.Pending) && (
                  <CFConfirmableButton
                    title={'Terminate intervention'}
                    question={'Are you sure to terminate this intervention?'}
                  >
                    <CFButton
                      value="Terminate"
                      iconName={faStop}
                      onClick={() => handleTerminate(row.intervention.id)}
                    />
                  </CFConfirmableButton>
                )}

              {false && (
                <CFCloneButton onClick={() => navigate(`${CFRoutes.intervention_new}?from=${row.intervention.id}`)} />
              )}

              {isAllowedTo(AuthAction.CreateIntervention) && (
                <CFConfirmableButton
                  title={'Remove intervention'}
                  question={'Are you sure to remove this intervention?'}
                >
                  <CFTrashButton onClick={() => handleRemoveIntervention(row.intervention.id)} />
                </CFConfirmableButton>
              )}
            </div>

            <InterventionDetail id={row.intervention.id} />
          </div>
        ),
      },
    ],
    [interventionService, interventions]
  );

  const handleViewMonitoring = useCallback((id: number) => {
    navigate(CFRoutes.intervention_monitoring_definition.replace(':id', `${id}`));
  }, []);

  const handlePause = useCallback(async (interventionId: InterventionId) => {
    const intervention = await interventionService.getView(interventionId);
    if (intervention.status === InterventionStatus.Paused) {
      await interventionService.resume(interventionId);
    } else {
      await interventionService.pause(interventionId);
    }

    // for future optimizations: instead of downloading the whole current page
    // we could update just current intervention
    downloadPage();
  }, []);

  const handleTerminate = async (interventionId: InterventionId) => {
    try {
      await interventionService.terminate(interventionId);

      const intervention = interventions.find((intv) => intv.intervention.id === interventionId);

      if (intervention) {
        intervention.status = InterventionStatus.Terminated;
      }

      // for future optimizations: instead of downloading the whole current page
      // we could update just current intervention
      downloadPage();
    } catch (err) {
      console.log(err);
      addToast('Error terminating intervention', ToastType.ERROR);
    }
  };

  const handleRemoveIntervention = useCallback(
    (id: number) => {
      removeIntervention(id)
        .then(() => {
          const filteredInterventions = interventions.filter((intervention) => intervention.intervention.id !== id);
          setInterventions(filteredInterventions);
        })
        .catch((err) => {
          console.log('Error deleting intervention: ', err);
        });
    },
    [interventions]
  );

  return (
    <div className="list-of-interventions">
      <CFNavList
        titles={allowedTabs()}
        selected={Tabs.Interventions}
        onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
      />
      <CFLoadWrapper isLoading={isLoading}>
        <div className="intervention-container">
          <div className="controls">
            {isAllowedTo(AuthAction.CreateIntervention) && (
              <CFButton
                value="Add Intervention"
                role={CFRole.Primary}
                iconName={faPlus}
                onClick={() => navigate(CFRoutes.intervention_new)}
              />
            )}
          </div>

          {totalInterventions !== 0 && totalInterventions !== -1 ? (
            <CFDataTable
              headers={columns}
              total={totalInterventions}
              data={interventions.map((data, index) => ({ ...data, id: `${curPage}:${index}` }))}
              onPaginated={handleNewPageRequest}
            />
          ) : (
            <div className="no-data-desc">No data available in table</div>
          )}
        </div>
      </CFLoadWrapper>

      <div className="intervention-container">
        Drafts
        <DraftTable />
      </div>
    </div>
  );
};

export default ListOfInterventions;
