import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useCFNavigation from 'hooks/useCFNavigation';
import { useServicesContext } from 'hooks/useServicesContext';

import { CFRoutes } from 'routes';

import { TraitSubject, TraitUsage } from 'domain/traits.types';

import { AlgorithmType, InterventionViewExtended } from 'services/intervention/intervention.types';

import InterventionDetail from 'views/intervention/components/InterventionDetail';

import { CFNavList } from 'components/CFNavList';
import CFTitledSection from 'components/CFTitledSection';
import Wizard from 'components/CFWizard';

import Schedule from '../../../../../connected-components/schedule';
import CohortDefinition from './cohort';
import GroupsDefinition from './groups';
import { monitoringByTab, Tabs } from '../tabs';
import { ABMetricsViewer, BanditMetricsViewer } from './metrics';
import ManualSample from './manualSample';
import MarkovStatesDefinition from './markov';

import { Steps } from '../../NewIntervention';

const InterventionDefinition = () => {
  const { interventionService, traitSessionService: traitService } = useServicesContext();

  const navigate = useCFNavigation();

  const [interventionView, setInterventionView] = useState<InterventionViewExtended>();
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        const view = await interventionService?.getView(parseInt(params.id as string));

        if (!view) {
          return;
        }
        // in AB testing with manually picked users, cohort is not set, so we use User as default
        await traitService.getTraits({
          subject: view.cohort.subject_type || TraitSubject.User,
          usage: TraitUsage.Cohort,
        });

        setInterventionView(view);
      } catch (err) {
        navigate(CFRoutes.intervention);
      }
    })();
  }, [interventionService]);

  if (!interventionView) {
    return <div></div>;
  }

  return (
    <div className="intervention-monitor-definition">
      <CFNavList
        titles={Object.values(Tabs)}
        selected={Tabs.Definition}
        onClick={(selectedTab) => navigate(monitoringByTab[selectedTab].replace(':id', params.id as string))}
      />
      <Wizard>
        <Wizard.Step name={Steps.Definition} ready={true}>
          <CFTitledSection
            title={`Intervention #${interventionView.intervention.id}: ${interventionView.intervention.name}`}
            id={Steps.Definition}
          >
            <InterventionDetail id={interventionView.intervention.id} />
          </CFTitledSection>
        </Wizard.Step>

        {interventionView.intervention.algo_policy.type === AlgorithmType.Bandit && (
          <Wizard.Step name={Steps.Metrics} ready={true}>
            <BanditMetricsViewer
              intervention={interventionView}
              traitService={traitService}
              className="metrics"
              step={Steps.Metrics}
            />
          </Wizard.Step>
        )}

        {interventionView.intervention.algo_policy.type === AlgorithmType.ABTest && (
          <Wizard.Step name={Steps.Metrics} ready={true}>
            <ABMetricsViewer
              intervention={interventionView}
              traitService={traitService}
              className="metrics"
              step={Steps.Metrics}
            />
          </Wizard.Step>
        )}

        <Wizard.Step name={Steps.Scheduling} ready={true}>
          <Schedule id={interventionView.schedule.id} className={'schedule'} label="intervention" />
        </Wizard.Step>

        <Wizard.Step name={Steps.Participants} ready={true}>
          <div id={Steps.Participants}>
            {interventionView.cohort.id !== 0 ? (
              <CohortDefinition
                sampleSize={interventionView.sampleSize || -1}
                extraFilters={interventionView.intervention.participant_policy.static_sample.extra_filters}
                cohort={interventionView.cohort}
                className="cohort"
                sampleId={interventionView.intervention.participant_policy.static_sample.sample_id}
              />
            ) : (
              <ManualSample
                sampleSize={interventionView.sampleSize || -1}
                className="cohort"
                sampleId={interventionView.intervention.participant_policy.static_sample.sample_id}
              />
            )}
          </div>
        </Wizard.Step>

        {interventionView.intervention.algo_policy.type === AlgorithmType.RestlessBandit && (
          <Wizard.Step name={Steps.States} ready={true}>
            <MarkovStatesDefinition intervention={interventionView} />
          </Wizard.Step>
        )}

        <Wizard.Step name={Steps.Nudge} ready={true}>
          <GroupsDefinition intervention={interventionView} className="groups" step={Steps.Nudge} />
        </Wizard.Step>
      </Wizard>
    </div>
  );
};

export default InterventionDefinition;
