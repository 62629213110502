import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Schedule, ScheduleType, StartEndPair } from 'services/scheduling/schedulting.types.api';
import { Granularity } from 'helpers/dates';

interface SchedulingContextValue {
  currentMonth: Date;
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;

  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;

  hoverDay: Date | undefined;
  setHoverDay: React.Dispatch<React.SetStateAction<Date | undefined>>;

  calendarDates: string[];
  setCalendarDates: React.Dispatch<React.SetStateAction<string[]>>;

  scheduleType: ScheduleType;
  setScheduleType: React.Dispatch<React.SetStateAction<ScheduleType>>;

  timezone: string;
  setTimezone: React.Dispatch<React.SetStateAction<string>>;

  startTime: string;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;

  granularity: Granularity | undefined;

  scheduleSetValues: Record<string, StartEndPair | null>;
  setScheduleSetValues: React.Dispatch<React.SetStateAction<Record<string, StartEndPair | null>>>;

  toggleCalendarDate: (date: Date) => void;

  availableScheduleTypes: ScheduleType[];
}

const SchedulingContext = createContext<SchedulingContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {
  defaultValue?: Schedule;
  editing?: boolean;
  forcedGranularity?: Granularity;
  availableTypes?: ScheduleType[];
}

const ContextProvider: React.FC<Props> = ({
  children,
  defaultValue,
  editing,
  forcedGranularity,
  availableTypes,
}: Props) => {
  const today = new Date();

  const defaultStartTime =
    defaultValue?.definition?.time_points?.pts?.[0] &&
    dayjs(defaultValue.definition.time_points.pts[0].slice(0, 19)).format('HH:mm');

  const [startTime, setStartTime] = useState(defaultStartTime || '00:00');

  const [timezone, setTimezone] = useState(
    defaultValue?.definition?.tz ? dayjs().utcOffset(defaultValue.definition.tz).format('Z') : dayjs.tz.guess()
  );

  const defaultCalendarDates =
    defaultValue?.definition?.time_points?.pts.map((date) => dayjs(date.slice(0, 19)).format(`YYYY-MM-DDT00:00:00`)) ||
    [];

  const [currentMonth, setCurrentMonth] = useState<Date>(today);
  const [selectedDate, setSelectedDate] = useState<Date>(today);
  const [hoverDay, setHoverDay] = useState<Date>();
  const [granularity, setGranularity] = useState<Granularity | undefined>(() => forcedGranularity);

  const [scheduleType, setScheduleType] = useState<ScheduleType>(defaultValue?.type || ScheduleType.TimePoints);

  const [calendarDates, setCalendarDates] = useState<string[]>(defaultCalendarDates || []);
  const [availableScheduleTypes, setAvailableScheduleTypes] = useState<ScheduleType[]>(
    availableTypes ?? [ScheduleType.Recurring, ScheduleType.TimePoints]
  );

  const defaultScheduleSetValues = defaultValue?.definition.recurring?.pairs.reduce<
    Record<string, StartEndPair | null>
  >((acc, val, idx) => {
    acc[new Date().valueOf() + idx] = val;

    return acc;
  }, {});

  const [scheduleSetValues, setScheduleSetValues] = useState<Record<string, StartEndPair | null>>(
    defaultScheduleSetValues || {
      [new Date().valueOf()]: null,
    }
  );

  useEffect(() => {
    setAvailableScheduleTypes(availableTypes ?? [ScheduleType.Recurring, ScheduleType.TimePoints]);
  }, [availableTypes]);

  useEffect(() => {
    if (!forcedGranularity) {
      setGranularity(undefined);

      return;
    }
    setGranularity(forcedGranularity);
    setScheduleType(ScheduleType.Recurring);
  }, [forcedGranularity]);

  const toggleCalendarDate = useCallback(
    (date: Date) => {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDate = dayjs(date).format('YYYY-MM-DD');

      if (editing && date < new Date()) {
        return;
      }

      /* 
        Compare only the date/time part, without timezone. the user might change the timezone
        during the usage and, in that case, comparing whole string does not work, because the 
        time zone may differ.

        To improve this, encapsulate dates in an object and add comparison capabilities to it
      */
      const newDates = calendarDates.filter(
        (_date) => _date.slice(0, dateFormat.length) !== formattedDate.slice(0, dateFormat.length)
      );

      if (newDates.length === calendarDates.length) {
        newDates.push(formattedDate);
      }

      setCalendarDates(newDates.map((date) => dayjs(date).format(`YYYY-MM-DDT00:00:00`)));
    },
    [calendarDates, timezone, startTime]
  );

  return (
    <SchedulingContext.Provider
      value={{
        currentMonth,
        setCurrentMonth,

        selectedDate,
        setSelectedDate,

        hoverDay,
        setHoverDay,

        scheduleType,
        setScheduleType,

        calendarDates,
        setCalendarDates,

        timezone,
        setTimezone,

        startTime,
        setStartTime,

        scheduleSetValues,
        setScheduleSetValues,

        granularity,

        toggleCalendarDate,
        availableScheduleTypes,
      }}
    >
      {children}
    </SchedulingContext.Provider>
  );
};

export const useSchedulingContext = (): SchedulingContextValue => {
  const context = useContext(SchedulingContext);
  if (!context) {
    throw new Error('useSchedulingContext must be used within an SchedulingContextProvider');
  }
  return context;
};

export default ContextProvider;
