import React from 'react';

import useCFNavigation from 'hooks/useCFNavigation';

import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';
import NudgeTable from 'views/intervention/components/nudges/NudgeTable';

import { CFNavList } from 'components/CFNavList';

import './list-of-nudges.scss';

const ListOfNudges = () => {
  const navigate = useCFNavigation();

  return (
    <div className="list-of-nudges">
      <CFNavList
        titles={allowedTabs()}
        selected={Tabs.Nudges}
        onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
      />

      <NudgeTable />
    </div>
  );
};

export default ListOfNudges;
