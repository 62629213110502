import React, { useCallback, useMemo, useRef } from 'react';
import debounce from 'debounce';

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { daysAgo } from 'helpers/dates';

import CFPaginatedList from 'components/CFPaginatedList';
import { Collapsible } from 'components/CollapsibleContent';
import CFDownloadButton from 'components/buttons/CFDownloadButton';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { ToastType } from 'components/CFToast/types';
import CFTabs from 'components/CFTabs';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';

import CohortSelector from 'connected-components/CohortSelector';

import { FilterAPI, Leaf, FilterGroupOperation } from 'services/cohort/cohort.types.api';
import { ParticipantPolicy, ParticipantType } from 'services/intervention/intervention.types';
import CohortService from 'services/cohort/cohort.service';
import Cohort from 'services/cohort/domain/Cohort';
import FilterSet from 'services/cohort/domain/FilterSet';
import Filter from 'services/cohort/domain/Filter';
import TraitService from 'services/traits/traitSession.service';

import { TraitSubject } from 'domain/traits.types';
import { SplitRatio } from 'domain/model.types';
import { Operators } from 'domain/general.types';

import { useInterventionContext } from 'views/intervention/useContext';
import ParticipantsManualPicker from 'views/intervention/components/ParticipantsManualPicker';

import { useToast } from 'hooks';

import BanditSampling from './BanditSampling';
import InterventionSection from '../interventionSection';
import { Steps } from '..';

const FIRST_INTERACTION_TRAIT = 'st_user.first_interaction';
const DEBOUNCE_OFFSET_TIMEOUT = 200;

import './intervention-participants.scss';
import VOSampleId from 'services/intervention/domain/VOSampleId';
import { SamplingType } from './types';

interface Props {
  cohortService: CohortService;
  traitService: TraitService;
  defaultValue?: {
    cohort: Cohort;
    extra_filters: FilterAPI[];
    splitRatio?: SplitRatio;
    sampleCount: number;
  };

  onReady: (ready: boolean) => void;
}

export interface InterventionParticipantsRef {
  value: () => {
    participants: ParticipantPolicy;
    splitRatio?: SplitRatio;
  };
}

const InterventionParticipantsRestless = forwardRef<InterventionParticipantsRef, Props>(
  function InterventionParticipants({ cohortService, defaultValue, traitService, onReady }: Props, ref) {
    const { addToast } = useToast();

    const [totalSubjects, setTotalSubjects] = useState(-1);
    const [subjects, setSubjects] = useState<string[]>([]);

    const [extraFilter, setExtraFilter] = useState<Leaf<Filter>>();

    const [curPage, setCurPage] = useState<number>(0);
    const [curPageSize, setCurPageSize] = useState<number>(15);

    const [isLoading, setIsloading] = useState(false);

    const offsetRef = useRef<HTMLInputElement>();

    const { sampleId, pureSampleId, setSampleId, cohort, setCohort, setSubject, setOffset, offset } =
      useInterventionContext();

    useImperativeHandle(ref, () => ({
      value() {
        return {
          participants: {
            type: ParticipantType.Static,
            static_sample: {
              sample_id: sampleId.value,
              extra_filters: extraFilter ? extraFilter.filters : [],
              pure_control_sample_id: pureSampleId.value,
              pure_control_extra_filter: extraFilter ? extraFilter.filters : [],
            },
            cohort_id: (cohort as Cohort)?.id, // in case of selecting subjects manually, cohort is undefined
          },
        };
      },
    }));

    useEffect(() => {
      onReady(sampleId !== undefined && sampleId.value !== '');
    }, [sampleId]);

    const buildFilterDataObject = (): FilterSet => {
      const filterSet = new FilterSet((cohort as Cohort).tree.getLegacy());

      if (extraFilter) {
        filterSet.addExtraFilter(extraFilter);
      }

      return filterSet;
    };

    useEffect(() => {
      (async () => {
        if (!cohort) {
          return;
        }

        setIsloading(true);

        const cohortFilterWithExtraFilters = buildFilterDataObject();
        cohortFilterWithExtraFilters.tree.op = FilterGroupOperation.And;
        try {
          const { total, data } = await cohortService.preview(cohortFilterWithExtraFilters, curPage, curPageSize);

          setTotalSubjects(total);
          setSubjects(data);
          setIsloading(false);
        } catch (err) {
          addToast('Error previewing sample', ToastType.ERROR, 5000);
        } finally {
          setIsloading(false);
        }
      })();
    }, [curPage, curPageSize, cohort, extraFilter]);

    useEffect(() => {
      if (!defaultValue) {
        return;
      }

      if (!defaultValue.cohort) {
        return;
      }

      setCohort(defaultValue.cohort);
      setSubject(defaultValue.cohort.subject_type as TraitSubject);
      resetSampleId();
    }, [defaultValue?.cohort]);

    useEffect(() => {
      if (!cohort) {
        return;
      }

      onReady(true);
    }, [cohort]);

    useEffect(() => {
      if (!cohort) {
        return;
      }

      traitService.initModelsForCohort(cohort.id);
    }, [cohort]);

    const resetSampleId = () => {
      onReady(false);

      setSampleId(new VOSampleId(''));
    };

    const handleNewPageRequest = async (page: number, size: number) => {
      setCurPage(page - 1);
      setCurPageSize(size);
    };

    const handleFetchSubjectsForDownload = useCallback(async () => {
      if (!cohort) {
        return [];
      }

      const cohortFilterWithExtraFilters = buildFilterDataObject();

      const allSubjects = await cohortService.previewAll(cohortFilterWithExtraFilters);
      return allSubjects || [];
    }, [cohort, extraFilter]);

    const handleOnCohortSelected = useCallback((cohort: Cohort) => {
      setCohort(cohort);
      setSubject(cohort.subject_type as TraitSubject);
      resetSampleId();
    }, []);

    const handleExtraFilterUpdated = useCallback((filter: Leaf<Filter>) => {
      setExtraFilter(filter);
    }, []);

    const handleOffsetUpdated = useCallback(() => {
      const offset = parseInt(offsetRef.current?.value || '0');

      setOffset(offset);
    }, [offsetRef, offsetRef.current]);

    const debouncedUpdateOffset = useCallback(debounce(handleOffsetUpdated, DEBOUNCE_OFFSET_TIMEOUT), []);

    const tabs = ['Select Cohorts', 'Manually Uploaded Subjects'];

    const extraFilters = useMemo(() => {
      return {
        filters: [
          new Filter({
            op: Operators.LessOrEqualThan,
            ptr: FIRST_INTERACTION_TRAIT,
            val: daysAgo(offset),
          }),
        ],
        op: FilterGroupOperation.And,
      };
    }, [offset]);

    return (
      <InterventionSection name={Steps.Participants} title={'Participants'}>
        <div className="intervention-participants intervention-section">
          <CFTabs.TabContext value={tabs[0]}>
            <CFTabs.Tabs>
              {/*
              Manual sampling for bandit is not possible atm 
              Remove `.slice(0, 1)` to enable manual sampling tab
             */}
              {tabs.slice(0, 1).map((tab: string) => (
                <CFTabs.Tab key={tab} value={tab}>
                  {tab}
                </CFTabs.Tab>
              ))}
            </CFTabs.Tabs>

            <CFTabs.TabPanel key={tabs[0]} value={tabs[0]}>
              <div className="intervention-participants">
                <CohortSelector
                  defaultCohort={cohort}
                  traitService={traitService}
                  onCohortSelected={handleOnCohortSelected}
                  onExtraFilterUpdated={handleExtraFilterUpdated}
                  defaultFilters={extraFilters}
                />

                <CFTitledComponent title="Offset" subtitle="(days)">
                  <CFInput
                    defaultValue={offset}
                    ref={(el) => (offsetRef.current = el as HTMLInputElement)}
                    onChange={debouncedUpdateOffset}
                    type={CFInputType.Number}
                    min={0}
                  />
                </CFTitledComponent>
                <CFLoadWrapper isLoading={isLoading} spinnerSize={30}>
                  <Collapsible sameStyling={true}>
                    <Collapsible.CollapsedHeader>
                      <div className="action-header">
                        <div className="subjects-title">
                          {totalSubjects !== -1 ? `Subjects (${totalSubjects})` : 'Subjects'}
                        </div>
                        <CFDownloadButton
                          getData={handleFetchSubjectsForDownload}
                          disabled={cohort === undefined}
                          name={`${cohort?.name.split(' ').join('-')}-subjects.csv`}
                        />
                      </div>
                    </Collapsible.CollapsedHeader>

                    <Collapsible.Content>
                      <CFPaginatedList
                        total={totalSubjects}
                        pageSizeList={[15, 30, 90]}
                        onPageChange={handleNewPageRequest}
                      >
                        {!subjects.length ? (
                          <span> Select a cohort first</span>
                        ) : (
                          <div className="subject-view">
                            {subjects.map((subject) => (
                              <div key={subject} className="subject-item">
                                {subject}
                              </div>
                            ))}
                          </div>
                        )}
                      </CFPaginatedList>
                    </Collapsible.Content>
                  </Collapsible>
                </CFLoadWrapper>

                <BanditSampling
                  cohortService={cohortService}
                  totalSubjects={totalSubjects}
                  isLoading={isLoading}
                  defaultSampleSize={defaultValue?.sampleCount || 0}
                  samplingType={SamplingType.Normal}
                />

                <BanditSampling
                  cohortService={cohortService}
                  totalSubjects={totalSubjects}
                  isLoading={isLoading}
                  defaultSampleSize={defaultValue?.sampleCount || 0}
                  samplingType={SamplingType.PureControl}
                />
              </div>
            </CFTabs.TabPanel>

            <CFTabs.TabPanel key={tabs[1]} value={tabs[1]}>
              <ParticipantsManualPicker groupNames={['subjects']} />
            </CFTabs.TabPanel>
          </CFTabs.TabContext>
        </div>
      </InterventionSection>
    );
  }
);

export default InterventionParticipantsRestless;
