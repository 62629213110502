import React, { useCallback } from 'react';

import { useToast } from 'hooks';
import useCFNavigation from 'hooks/useCFNavigation';

import { CFRoutes } from 'routes';

import ModelDefinitionDetails from 'views/model/components/ModelDetails';
import useURLModel from 'views/model/hooks/useURLModel';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { remove as removeModel } from 'services/model/model.repo';

import CFTrashButton from 'components/buttons/CFTrashButton';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import { ToastType } from 'components/CFToast/types';
import { Steps } from 'views/intervention/interventions/NewIntervention';

const ModelDefinitionView = () => {
  const model = useURLModel();
  const navigate = useCFNavigation();

  const { addToast } = useToast();

  const handleRemoveModel = useCallback(() => {
    if (!model?.definition?.id) {
      return;
    }

    removeModel(model.definition.id)
      .then(() => {
        navigate(CFRoutes.model);
        addToast(`Model deleted`, ToastType.SUCCESS);
      })
      .catch((err) => {
        addToast(`Error deleting model`, ToastType.ERROR);
        console.log('Error deleting model: ', err);
      });
  }, [model]);

  const modelActions = (
    <div className="actions">
      {isAllowedTo(AuthAction.CreateModel) && model?.definition?.purpose !== 'inv' && (
        <CFConfirmableButton title={'Remove model'} question={'Are you sure to remove this model?'}>
          <CFTrashButton onClick={() => handleRemoveModel()} />
        </CFConfirmableButton>
      )}
    </div>
  );

  if (!model) {
    return <div></div>;
  }
  return (
    <CFTitledSection title={`Model #${model.definition.id}`} id={Steps.Definition}>
      <SectionAction>{modelActions}</SectionAction>
      <ModelDefinitionDetails model={model} />
    </CFTitledSection>
  );
};

export default ModelDefinitionView;
