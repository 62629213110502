import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';
import RelativeInfoBox from 'components/kpis/RelativeInfoBox';
import CFPortal, { ContentPosition } from 'components/CFPortal';
import { ToastType } from 'components/CFToast/types';
import CFModal from 'components/CFModal';

import ChurnInfo from '../ChurnInfo';
import DataInfoBox from '../DataInfoBox';
import DataInfoErrorBoundary from '../DataInfoBox/dataInfoBoxErrorBoundary';
import DetailedChurnPrediction from '../DetailedChurnPrediction';
import DetailedAppPerformance from '../DetailedAppPerformance';

import { Trait, TraitMetricsNames } from 'domain/traits.types';
import { AnalyticSummary, CuratedTraitSummaryResult, TraitSummaryResult } from 'domain/analytic.types';

import useCFNavigation from 'hooks/useCFNavigation';
import { useServicesContext } from 'hooks/useServicesContext';

import { convertUnitsFromTo } from 'helpers/unitconversion';

import { useToast } from 'hooks';
import { CFRoutes } from 'routes';

import { getDisplayName } from 'services/traits/helpers.traits';

import useChurnRatio from '../useChurnRatio';

import './kpi-matrix.scss';

const sortedMetrics: TraitMetricsNames[] = [
  TraitMetricsNames.ActiveUserCountDay,
  TraitMetricsNames.ActiveUserCountMonth,
  TraitMetricsNames.NewUserCountDay,
  TraitMetricsNames.ChurnRatio,
];

// false --> down arrows are good
// true --> down arrows are bad
const sortedMetricsLogic: Partial<Record<TraitMetricsNames, boolean>> = {
  [TraitMetricsNames.ActiveUserCountDay]: true,
  [TraitMetricsNames.ActiveUserCountMonth]: true,
  [TraitMetricsNames.NewUserCountDay]: true,
  [TraitMetricsNames.ChurnRatio]: false,
};

/** flags */
const showChurnRatioDetails = true;

interface Props {
  traits: Trait[];
}

const getKPIDisplayName = (trait: Trait) => {
  if (trait?.meta?.display_name.toLowerCase().startsWith('churn ratio')) {
    return 'Churn Ratio';
  }

  return getDisplayName(trait);
};

const KPIMatrix = ({ traits }: Props) => {
  const navigate = useCFNavigation();
  const churnRatio = useChurnRatio();

  const { addToast } = useToast();
  const [showAppPerformance, setShowAppPerformance] = useState(false);
  const [showChurnersPrediction, setShowChurnersPrediction] = useState(false);

  const [rawMetrics, setRawMetrics] = useState<AnalyticSummary>({
    landing_cohort_id: 0,
    intervention_stats: {
      total: 0,
      active: 0,
    },
    trait_summary_results: {},
  });

  const { traitSessionService: traitService, landingService } = useServicesContext();

  const [curatedTraitSummary, setCuratedTraitSummary] = useState<Record<string, CuratedTraitSummaryResult>>({});
  const [landingCohortId, setLandingCohort] = useState<number>(-1);

  const sortedMetricsWithChurnRatio = useMemo(() => {
    if (churnRatio) {
      return [...sortedMetrics.slice(0, -1), `${TraitMetricsNames.ChurnRatio}#${churnRatio.toString()}`];
    } else {
      return [...sortedMetrics, TraitMetricsNames.ChurnRatio];
    }
  }, [churnRatio]);

  const churnRatioName = useMemo(() => {
    if (churnRatio) {
      return `${TraitMetricsNames.ChurnRatio}#${churnRatio.toString()}`;
    } else {
      return TraitMetricsNames.ChurnRatio;
    }
  }, [churnRatio]);

  useEffect(() => {
    (async () => {
      if (!traits) {
        return;
      }

      try {
        const analytics = await landingService.getAnalytics();
        const curatedTraitSummary: Record<string, CuratedTraitSummaryResult> = {};

        Object.keys(analytics.trait_summary_results).map((traitKey) => {
          const trait = traitService.getTraitDefinition(traitKey);

          try {
            const convertedValue = convertUnitsFromTo(
              analytics.trait_summary_results[traitKey].current,
              trait.meta.unit,
              trait.meta.display_unit
            );

            curatedTraitSummary[traitKey] = {
              ...(analytics.trait_summary_results as unknown as TraitSummaryResult),
              value: convertedValue,
              unit: trait.meta.display_unit,
              label: getDisplayName(trait),
              variation: analytics.trait_summary_results[traitKey].percentage * 100,
            };
          } catch (e) {
            curatedTraitSummary[traitKey] = {
              ...(analytics.trait_summary_results as unknown as TraitSummaryResult),
              value: analytics.trait_summary_results[traitKey].current,
              unit: analytics.trait_summary_results[traitKey].unit || trait.meta.unit,
              label: getDisplayName(trait),
              variation: analytics.trait_summary_results[traitKey].percentage * 100,
            };
          }
        });

        const landingCohortId = await landingService.getLandingCohortId();

        setLandingCohort(landingCohortId);
        setCuratedTraitSummary(curatedTraitSummary);
        setRawMetrics(analytics);
      } catch (err: any) {
        addToast(`Error getting analytics information: ${err.message}`, ToastType.ERROR, 5000);
      }
    })();
  }, [traits]);

  const isLoading = useMemo(() => {
    return !Object.keys(curatedTraitSummary).length;
  }, [curatedTraitSummary]);

  const handleShowAppPerformanceInfo = useCallback(() => {
    setShowAppPerformance(true);
  }, []);

  const handleShowChurnersPreditions = useCallback(() => {
    setShowChurnersPrediction(true);
  }, []);

  return (
    <div className="kpi-matrix">
      {showAppPerformance && (
        <CFModal onClose={() => setShowAppPerformance(false)} className="detailed-app-performance-modal">
          <DetailedAppPerformance traitService={traitService} allSubjectCohortId={landingCohortId} />
        </CFModal>
      )}

      {showChurnersPrediction && (
        <CFPortal onClickOutside={() => setShowChurnersPrediction(false)} mode={ContentPosition.Center}>
          <DetailedChurnPrediction onClose={() => setShowChurnersPrediction(false)} />
        </CFPortal>
      )}
      <div className="cards-by-type">
        <div className="relative-cards">
          {sortedMetricsWithChurnRatio.map((metric, i) => {
            return (
              <RelativeInfoBox
                key={i}
                isLoading={isLoading}
                value={curatedTraitSummary[metric]?.value}
                label={getKPIDisplayName(traitService.getTraitDefinition(metric))}
                unit={curatedTraitSummary[metric]?.unit === '%' ? curatedTraitSummary[metric].unit : ''}
                variation={curatedTraitSummary[metric]?.variation}
                reversedLogic={
                  sortedMetricsLogic[metric as TraitMetricsNames]
                    ? !sortedMetricsLogic[metric as TraitMetricsNames]
                    : false
                }
                onShowExtendedInfo={
                  metric === churnRatioName && showChurnRatioDetails ? handleShowChurnersPreditions : undefined
                }
              >
                {metric === churnRatioName && (
                  <RelativeInfoBox.Info>
                    <CFTooltip description={<ChurnInfo />} position={CFTooltipPositions.Right} anchor={``}>
                      <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
                    </CFTooltip>
                  </RelativeInfoBox.Info>
                )}
              </RelativeInfoBox>
            );
          })}
          <DataInfoErrorBoundary>
            <DataInfoBox
              isLoading={isLoading}
              title="Interventions"
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClickedKPI={(label: string) => {
                // if the backend is able to send the IDS of these intervention we could
                // redirect directly to the intervention detail
                navigate(CFRoutes.intervention);
              }}
              kpis={[
                {
                  value: rawMetrics['intervention_stats'].total,
                  unit: '',
                  label: 'Total',
                  variation: 0,
                },
                {
                  value: rawMetrics['intervention_stats'].active,
                  unit: '',
                  label: 'Active',
                  variation: 0,
                },
              ]}
            />
          </DataInfoErrorBoundary>
        </div>
        <div className="data-info-boxes">
          <DataInfoErrorBoundary>
            <DataInfoBox
              isLoading={isLoading}
              title="Push notifications"
              kpis={[
                {
                  value: convertUnitsFromTo(
                    rawMetrics['trait_summary_results'][TraitMetricsNames.PushOpenRate]?.current,
                    traitService.getTraitDefinition(TraitMetricsNames.PushOpenRate)?.meta.unit,
                    traitService.getTraitDefinition(TraitMetricsNames.PushOpenRate)?.meta.display_unit
                  ),
                  unit: traitService.getTraitDefinition(TraitMetricsNames.PushOpenRate)?.meta.display_unit,
                  label: getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.PushOpenRate)),
                  variation: 0,
                },
              ]}
            />
          </DataInfoErrorBoundary>

          <DataInfoErrorBoundary>
            <DataInfoBox
              isLoading={isLoading}
              title="Engagement"
              kpis={[
                {
                  value: curatedTraitSummary[TraitMetricsNames.ConnectionInterval]?.value,
                  unit: curatedTraitSummary[TraitMetricsNames.ConnectionInterval]?.unit,
                  label: curatedTraitSummary[TraitMetricsNames.ConnectionInterval]?.label,
                  variation: 0,
                },
                {
                  value: curatedTraitSummary[TraitMetricsNames.ConnectionTime]?.value || '-',
                  unit: curatedTraitSummary[TraitMetricsNames.ConnectionTime]?.unit,
                  label: curatedTraitSummary[TraitMetricsNames.ConnectionTime]?.label,
                  variation: 0,
                },
              ]}
            />
          </DataInfoErrorBoundary>

          {(isLoading ||
            (rawMetrics['trait_summary_results'][TraitMetricsNames.SpeedUp] !== undefined &&
              rawMetrics['trait_summary_results'][TraitMetricsNames.SpeedDown] !== undefined)) && (
            <DataInfoErrorBoundary>
              <DataInfoBox
                isLoading={isLoading}
                title="Connectivity"
                units={`(${curatedTraitSummary[TraitMetricsNames.SpeedDown]?.unit})`}
                kpis={[
                  {
                    value: `${curatedTraitSummary[TraitMetricsNames.SpeedUp]?.value.toFixed(1)}/${curatedTraitSummary[
                      TraitMetricsNames.SpeedDown
                    ]?.value.toFixed(1)}`,
                    unit: '',
                    label: 'Up/down Speed',
                    variation: 0,
                  },
                ]}
              />
            </DataInfoErrorBoundary>
          )}

          <DataInfoErrorBoundary>
            <DataInfoBox
              isLoading={isLoading}
              title="App performance"
              onShowExtendedInfo={handleShowAppPerformanceInfo}
              kpis={[
                {
                  value: curatedTraitSummary[TraitMetricsNames.AppStartTime]?.value,
                  unit: curatedTraitSummary[TraitMetricsNames.AppStartTime]?.unit,
                  label: curatedTraitSummary[TraitMetricsNames.AppStartTime]?.label,
                  variation: 0,
                },
                {
                  value: curatedTraitSummary[TraitMetricsNames.AppRenderingTime]?.value,
                  unit: curatedTraitSummary[TraitMetricsNames.AppRenderingTime]?.unit,
                  label: curatedTraitSummary[TraitMetricsNames.AppRenderingTime]?.label,
                  variation: 0,
                },
              ]}
            />
          </DataInfoErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default KPIMatrix;
