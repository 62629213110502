import React, { useCallback, useMemo } from 'react';

import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';

import { Schedule, ScheduleType } from 'services/scheduling/schedulting.types.api';
import { useSchedulingContext } from '../context/useSchedulingContext';

interface Props {
  defaultValue?: Schedule;
}

const defaultTypeOptions = [
  {
    label: 'Calendar',
    value: ScheduleType.TimePoints,
  },
  {
    label: 'Recurring',
    value: ScheduleType.Recurring,
  },
];

const CalendarTypeSelector = ({ defaultValue }: Props) => {
  const { scheduleType, setScheduleType, availableScheduleTypes } = useSchedulingContext();

  const scheduleTypeOptions = useMemo(() => {
    let filteredOptions = [...defaultTypeOptions];

    filteredOptions = filteredOptions.filter((option) => availableScheduleTypes.includes(option.value));

    if (!defaultValue) {
      return filteredOptions;
    }

    if (!defaultValue.definition.recurring) {
      return filteredOptions.filter((item) => item.value === ScheduleType.TimePoints);
    }

    if (!defaultValue.definition.time_points) {
      return filteredOptions.filter((item) => item.value === ScheduleType.Recurring);
    }

    return defaultTypeOptions;
  }, [defaultValue, availableScheduleTypes]);

  const handleSelect = useCallback((option: ButtonGroupOption) => {
    setScheduleType(option.value);
  }, []);

  const selectedOption = useMemo(() => {
    return defaultTypeOptions.find((option) => option.value === scheduleType) || defaultTypeOptions[0];
  }, [scheduleType]);

  return (
    <CFButtonGroup
      className="intervention-schedule-type"
      options={scheduleTypeOptions}
      value={selectedOption}
      onSelect={handleSelect}
    />
  );
};

export default CalendarTypeSelector;
