import React, { useCallback, useState } from 'react';

import CFButton from 'components/buttons/CFButton';
import { CFRole } from 'domain/general.types';
import { CensoringMetricsWrap } from 'domain/model.types';
import LifetimeChart from '../components/LifetimeChart';
import PredictedVSObservedLifetime from '../components/PredictedVSObservedLifetime';
import ScoreTimeSerie from '../components/ScoreTimeSerie';
import SurvivalCurve from '../components/SurvivalCurve';
import { cindexIPCWText, cindexText } from '../constants';

const buttonTabs = [
  { label: 'Scores', value: 'scores' },
  { label: 'Plot', value: 'plot' },
] as const;

type ButtonTabs = (typeof buttonTabs)[number]['value'];

interface Props {
  metrics: CensoringMetricsWrap;
}

const CensoringMetricsView = ({ metrics }: Props) => {
  const [currentButtonTab, setCurrentButtonTab] = useState<ButtonTabs>(buttonTabs[0].value);

  const handleCurrentButton = useCallback(
    (buttonTab: ButtonTabs) => () => {
      setCurrentButtonTab(buttonTab);
    },
    []
  );

  return (
    <div className="model-metrics-container">
      <div className="model-metrics-controls">
        {buttonTabs.map(({ label, value }) => (
          <CFButton
            key={value}
            value={label}
            onClick={handleCurrentButton(value)}
            role={currentButtonTab === value ? CFRole.Cyan : CFRole.Secondary}
          />
        ))}
      </div>

      {currentButtonTab === 'scores' && (
        <div className="model-metrics-scores">
          <ScoreTimeSerie modelMetrics={metrics as CensoringMetricsWrap} description={cindexText} type="cindex" />
          <ScoreTimeSerie
            modelMetrics={metrics as CensoringMetricsWrap}
            description={cindexIPCWText}
            type="cindex_ipcw"
          />
        </div>
      )}

      {metrics && currentButtonTab === 'plot' && (
        <div className="model-metrics-plots">
          <PredictedVSObservedLifetime className="lifetime" censoringMetrics={metrics as CensoringMetricsWrap} />
          <LifetimeChart className="lifetimeNext" />
          <SurvivalCurve className="survival" />
        </div>
      )}
    </div>
  );
};

export default CensoringMetricsView;
