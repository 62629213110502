import React, { useCallback, useMemo, useRef } from 'react';

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { SplitRatio } from 'domain/model.types';
import { TraitSubject } from 'domain/traits.types';

import Cohort from 'services/cohort/domain/Cohort';
import FilterSet from 'services/cohort/domain/FilterSet';
import Filter from 'services/cohort/domain/Filter';
import VOSampleId from 'services/intervention/domain/VOSampleId';
import { FilterAPI, Leaf, FilterGroupOperation } from 'services/cohort/cohort.types.api';
import { ParticipantPolicy, ParticipantType } from 'services/intervention/intervention.types';
import CohortService from 'services/cohort/cohort.service';
import TraitService from 'services/traits/traitSession.service';

import { useInterventionContext } from 'views/intervention/useContext';
import ParticipantsManualPicker from 'views/intervention/components/ParticipantsManualPicker';

import CohortSelector from 'connected-components/CohortSelector';
import CohortViewer from 'connected-components/CohortViewer';

import { Collapsible } from 'components/CollapsibleContent';
import CFDownloadButton from 'components/buttons/CFDownloadButton';
import CFLoadWrapper from 'components/CFLoadWrapper';
import CFSplitRatios, { CFSplitRatiosRef } from 'components/CFSplitRatios';
import CFTabs from 'components/CFTabs';

import { Steps } from '..';
import InterventionSection from '../interventionSection';
import BanditSampling from './BanditSampling';

import { SamplingType } from './types';

import './intervention-participants.scss';

interface Props {
  cohortService: CohortService;
  traitService: TraitService;
  defaultValue?: {
    cohort: Cohort;
    extra_filters: FilterAPI[];
    splitRatio?: SplitRatio;
    sampleCount: number;
  };

  onReady: (ready: boolean) => void;
}

export interface InterventionParticipantsRef {
  value: () => {
    participants: ParticipantPolicy;
    splitRatio?: SplitRatio;
  };
}

const InterventionParticipantsBandit = forwardRef<InterventionParticipantsRef, Props>(function InterventionParticipants(
  { cohortService, defaultValue, traitService, onReady }: Props,
  ref
) {
  const [totalSubjects, setTotalSubjects] = useState(-1);

  const [isLoading, setIsloading] = useState(false);

  const { sampleId, setSampleId, pureSampleId, cohort, setCohort, setSubject, extraFilter, setExtraFilter } =
    useInterventionContext();

  const splitRatioRef = useRef<CFSplitRatiosRef>();

  useImperativeHandle(ref, () => ({
    value() {
      return {
        participants: {
          type: ParticipantType.Static,
          static_sample: {
            sample_id: sampleId.value,
            pure_control_sample_id: pureSampleId.value,
            pure_control_extra_filter: extraFilter ? extraFilter.filters : [],
            extra_filters: extraFilter ? extraFilter.filters : [],
          },
          cohort_id: (cohort as Cohort)?.id, // in case of selecting subjects manually, cohort is undefined
        },
        splitRatio: splitRatioRef.current?.value(),
      };
    },
  }));

  useEffect(() => {
    onReady(sampleId !== undefined && sampleId.value !== '');
  }, [sampleId]);

  useEffect(() => {
    (async () => {
      if (!cohort) {
        return;
      }

      setIsloading(true);
      const cohortFilterWithExtraFilters = buildFilterDataObject();

      const { total } = await cohortService.preview(cohortFilterWithExtraFilters, 0, 10);

      setIsloading(false);
      setTotalSubjects(total);
    })();
  }, [cohort, extraFilter]);

  const buildFilterDataObject = (): FilterSet => {
    const filterSet = new FilterSet((cohort as Cohort).tree.getLegacy());

    if (extraFilter) {
      filterSet.addExtraFilter(extraFilter);
    }

    return filterSet;
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    if (!defaultValue.cohort) {
      return;
    }

    setCohort(defaultValue.cohort);
    setSubject(defaultValue.cohort.subject_type as TraitSubject);
    resetSampleId();
  }, [defaultValue?.cohort]);

  useEffect(() => {
    if (!cohort) {
      return;
    }

    traitService.initModelsForCohort(cohort.id);
  }, [cohort]);

  const resetSampleId = () => {
    onReady(false);

    setSampleId(new VOSampleId(''));
  };

  const handleFetchSubjectsForDownload = useCallback(async () => {
    if (!cohort) {
      return [];
    }

    const cohortFilterWithExtraFilters = buildFilterDataObject();

    const allSubjects = await cohortService.previewAll(cohortFilterWithExtraFilters);
    return allSubjects || [];
  }, [cohort, extraFilter]);

  const handleOnCohortSelected = useCallback((cohort: Cohort) => {
    setCohort(cohort);
    setSubject(cohort.subject_type as TraitSubject);
    resetSampleId();
  }, []);

  const handleExtraFilterUpdated = useCallback((filter: Leaf<Filter>) => {
    setExtraFilter(filter);
  }, []);

  const tabs = ['Select Cohorts', 'Manually Uploaded Subjects'];

  const extraFilters = useMemo(() => {
    return {
      filters: defaultValue?.extra_filters || [],
      op: FilterGroupOperation.And,
    };
  }, [defaultValue]);

  return (
    <InterventionSection name={Steps.Participants} title={'Participants'}>
      <div className="intervention-participants intervention-section">
        <CFTabs.TabContext value={tabs[0]}>
          <CFTabs.Tabs>
            {/*
              Manual sampling for bandit is not possible atm 
              Remove `.slice(0, 1)` to enable manual sampling tab
             */}
            {tabs.slice(0, 1).map((tab: string) => (
              <CFTabs.Tab key={tab} value={tab}>
                {tab}
              </CFTabs.Tab>
            ))}
          </CFTabs.Tabs>

          <CFTabs.TabPanel key={tabs[0]} value={tabs[0]}>
            <div className="intervention-participants">
              <CohortSelector
                defaultCohort={defaultValue?.cohort}
                traitService={traitService}
                onCohortSelected={handleOnCohortSelected}
                onExtraFilterUpdated={handleExtraFilterUpdated}
                defaultFilters={extraFilters}
              />
              <CFLoadWrapper isLoading={isLoading} spinnerSize={30}>
                <Collapsible sameStyling={true}>
                  <Collapsible.CollapsedHeader>
                    <div className="action-header">
                      <div className="subjects-title">
                        {totalSubjects !== -1 ? `Subjects (${totalSubjects})` : 'Subjects'}
                      </div>
                      <CFDownloadButton
                        getData={handleFetchSubjectsForDownload}
                        disabled={cohort === undefined}
                        name={`${cohort?.name.split(' ').join('-')}-subjects.csv`}
                      />
                    </div>
                  </Collapsible.CollapsedHeader>

                  <Collapsible.Content>
                    <CohortViewer cohort={cohort} extraFilter={extraFilter} />
                  </Collapsible.Content>
                </Collapsible>
              </CFLoadWrapper>

              <BanditSampling
                cohortService={cohortService}
                totalSubjects={totalSubjects}
                isLoading={isLoading}
                defaultSampleSize={defaultValue?.sampleCount || 0}
                samplingType={SamplingType.Normal}
              />

              <BanditSampling
                cohortService={cohortService}
                totalSubjects={totalSubjects}
                isLoading={isLoading}
                defaultSampleSize={defaultValue?.sampleCount || 0}
                samplingType={SamplingType.PureControl}
              />

              <CFSplitRatios
                ref={(el) => (splitRatioRef.current = el as CFSplitRatiosRef)}
                defaultValue={defaultValue?.splitRatio}
              />
            </div>
          </CFTabs.TabPanel>

          <CFTabs.TabPanel key={tabs[1]} value={tabs[1]}>
            <ParticipantsManualPicker groupNames={['subjects']} />
          </CFTabs.TabPanel>
        </CFTabs.TabContext>
      </div>
    </InterventionSection>
  );
});

export default InterventionParticipantsBandit;
