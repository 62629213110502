import React, { useCallback, useMemo } from 'react';

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import CFPaginatedList from 'components/CFPaginatedList';
import { Collapsible } from 'components/CollapsibleContent';
import CFDownloadButton from 'components/buttons/CFDownloadButton';

import { Leaf, FilterGroupOperation } from 'services/cohort/cohort.types.api';

import { TraitSubject } from 'domain/traits.types';
import { ParticipantPolicy, ParticipantType } from 'services/intervention/intervention.types';
import CohortSelector from 'connected-components/CohortSelector';

import CohortService from 'services/cohort/cohort.service';

import { Steps } from '..';
import InterventionSection from '../interventionSection';

import { useInterventionContext } from 'views/intervention/useContext';

import ABSampling from './ABSampling';
import CFTabs from 'components/CFTabs';
import ParticipantsManualPicker from 'views/intervention/components/ParticipantsManualPicker';

import TraitService from 'services/traits/traitSession.service';

import CFLoadWrapper from 'components/CFLoadWrapper';
import { ToastType } from 'components/CFToast/types';
import { useToast } from 'hooks';
import { SplitRatio } from 'domain/model.types';
import Cohort from 'services/cohort/domain/Cohort';
import FilterSet from 'services/cohort/domain/FilterSet';
import Filter from 'services/cohort/domain/Filter';

import VOSampleId from 'services/intervention/domain/VOSampleId';

import './intervention-participants.scss';

interface Props {
  cohortService: CohortService;
  traitService: TraitService;
  onReady: (ready: boolean) => void;
}

export interface InterventionParticipantsRef {
  value: () => {
    participants: ParticipantPolicy;
    splitRatio?: SplitRatio;
  };
}

const InterventionParticipantsAB = forwardRef<InterventionParticipantsRef, Props>(function InterventionParticipants(
  { cohortService, traitService, onReady }: Props,
  ref
) {
  const { addToast } = useToast();

  const [totalSubjects, setTotalSubjects] = useState(-1);
  const [subjects, setSubjects] = useState<string[]>([]);

  const [extraFilter, setExtraFilter] = useState<Leaf<Filter>>();

  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(15);

  const [isLoading, setIsloading] = useState(false);

  const { sampleId, setSampleId, cohort, setCohort, selectedNudges, setSubject } = useInterventionContext();

  useImperativeHandle(ref, () => ({
    value() {
      return {
        participants: {
          type: ParticipantType.Static,
          static_sample: {
            sample_id: sampleId.value,
            extra_filters: extraFilter ? extraFilter.filters : [],
          },
          cohort_id: (cohort as Cohort)?.id, // in case of selecting subjects manually, cohort is undefined
        },
      };
    },
  }));

  useEffect(() => {
    onReady(sampleId !== undefined && sampleId.value !== '');
  }, [sampleId]);

  useEffect(() => {
    if (!cohort) {
      return;
    }

    traitService.initModelsForCohort(cohort.id);
  }, [cohort]);

  const buildFilterDataObject = (): FilterSet => {
    const filterSet = new FilterSet((cohort as Cohort).tree.getLegacy());

    if (extraFilter) {
      filterSet.addExtraFilter(extraFilter);
    }

    return filterSet;
  };

  useEffect(() => {
    (async () => {
      if (!cohort) {
        return;
      }

      if (isLoading) {
        return;
      }

      setIsloading(true);

      const cohortFilterWithExtraFilters = buildFilterDataObject();
      try {
        const { total, data } = await cohortService.preview(cohortFilterWithExtraFilters, curPage, curPageSize);

        setTotalSubjects(total);
        setSubjects(data);
      } catch (err) {
        addToast('Error previewing sample', ToastType.ERROR, 5000);
      } finally {
        setIsloading(false);
      }
    })();
  }, [cohort, curPage, curPageSize, extraFilter]);

  useEffect(() => {
    if (!cohort) {
      return;
    }

    onReady(true);
  }, [cohort]);

  const resetSampleId = () => {
    onReady(false);
    setSampleId(new VOSampleId(''));
  };

  const handleNewPageRequest = async (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleFetchSubjectsForDownload = useCallback(async () => {
    if (!cohort) {
      return [];
    }

    const cohortFilterWithExtraFilters = buildFilterDataObject();

    const allSubjects = await cohortService.previewAll(cohortFilterWithExtraFilters);
    return allSubjects || [];
  }, [cohort]);

  const handleOnCohortSelected = useCallback((cohort: Cohort) => {
    setCohort(cohort);
    setSubject(cohort.subject_type as TraitSubject);
    resetSampleId();
  }, []);

  const handleExtraFilterUpdated = useCallback((filter: Leaf<Filter>) => {
    setExtraFilter(filter);
  }, []);

  const tabs = ['Select Cohorts', 'Manually Uploaded Subjects'];

  const defaultFilters = useMemo(() => {
    return {
      filters: [],
      op: FilterGroupOperation.And,
    };
  }, []);

  return (
    <InterventionSection name={Steps.Participants} title={'Participants'}>
      <div className="intervention-participants intervention-section">
        <CFTabs.TabContext value={tabs[0]}>
          <CFTabs.Tabs>
            {tabs.map((tab: string) => (
              <CFTabs.Tab key={tab} value={tab}>
                {tab}
              </CFTabs.Tab>
            ))}
          </CFTabs.Tabs>

          <CFTabs.TabPanel key={tabs[0]} value={tabs[0]}>
            <div className="intervention-participants">
              <CohortSelector
                traitService={traitService}
                onCohortSelected={handleOnCohortSelected}
                onExtraFilterUpdated={handleExtraFilterUpdated}
                defaultFilters={defaultFilters}
              />
              <CFLoadWrapper isLoading={isLoading} spinnerSize={30}>
                <Collapsible sameStyling={true}>
                  <Collapsible.CollapsedHeader>
                    <div className="action-header">
                      <div className="subjects-title">
                        {totalSubjects !== -1 ? `Subjects (${totalSubjects})` : 'Subjects'}
                      </div>
                      <CFDownloadButton
                        getData={handleFetchSubjectsForDownload}
                        disabled={cohort === undefined}
                        name={`${cohort?.name.split(' ').join('-')}-subjects.csv`}
                      />
                    </div>
                  </Collapsible.CollapsedHeader>

                  <Collapsible.Content>
                    <CFPaginatedList
                      total={totalSubjects}
                      pageSizeList={[15, 30, 90]}
                      onPageChange={handleNewPageRequest}
                    >
                      {!subjects.length ? (
                        <span> Select a cohort first</span>
                      ) : (
                        <div className="subject-view">
                          {subjects.map((subject) => (
                            <div key={subject} className="subject-item">
                              {subject}
                            </div>
                          ))}
                        </div>
                      )}
                    </CFPaginatedList>
                  </Collapsible.Content>
                </Collapsible>
              </CFLoadWrapper>

              <ABSampling
                cohortService={cohortService}
                extraFilter={extraFilter}
                totalSubjects={totalSubjects}
                isLoading={isLoading}
              />
            </div>
          </CFTabs.TabPanel>

          <CFTabs.TabPanel key={tabs[1]} value={tabs[1]}>
            <ParticipantsManualPicker groupNames={selectedNudges.map((nudge) => nudge.name)} />
          </CFTabs.TabPanel>
        </CFTabs.TabContext>
      </div>
    </InterventionSection>
  );
});

export default InterventionParticipantsAB;
