import React, { forwardRef } from 'react';

import { Schedule, ScheduleType } from 'services/scheduling/schedulting.types.api';

import SchedulingBuilderInternal from './schedulingBuilder';

import SchedulingContext from './context/useSchedulingContext';

import { Granularity } from 'helpers/dates';

import './scheduling.scss';

export interface SchedulingBuilderRef {
  value: () => Schedule;
}

interface Props {
  defaultValue?: Schedule;
  editing?: boolean;
  forcedGranularity?: Granularity;
  availableTypes?: ScheduleType[];
  onReady: (ready: boolean) => void;
}

const SchedulingBuilder = forwardRef<SchedulingBuilderRef, Props>(function InterventionScheduling(props: Props, ref) {
  return (
    <SchedulingContext {...props}>
      <SchedulingBuilderInternal ref={ref} {...props} />
    </SchedulingContext>
  );
});

export default SchedulingBuilder;
