import React from 'react';

import { AppModel } from 'domain/model.types';
import ModelCohort from '../components/cohort';
import Schedule from 'connected-components/schedule';
import ModelRuns from '../components/runs';
import useCohort from 'views/segmentation/cohorts/hooks/useCohort';
import EmbeddingParamsViewer from './params';
import CFTitledSection from 'components/CFTitledSection';
import ModelDefinitionView from '../components/definition';
import Wizard from 'components/CFWizard';
import { Steps } from 'views/model/creation/useContext';

interface Props {
  model: AppModel;
}

const ModelEmbeddingDetails = ({ model }: Props) => {
  const userCohort = useCohort({ id: `${model.definition.population_policy.cohort.cohort_id}` });
  const itemCohort = useCohort({ id: `${model.definition.data_defn.embedding?.item_policy.cohort.cohort_id}` });

  return (
    <Wizard>
      <Wizard.Step name={Steps.Definition} ready={true}>
        <ModelDefinitionView />
      </Wizard.Step>

      <Wizard.Step name={Steps.UserCohort} ready={true}>
        <CFTitledSection title="User Cohort" id={Steps.UserCohort}>
          <ModelCohort model={model} cohort={userCohort} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.ItemCohort} ready={true}>
        <CFTitledSection title="Item Cohort" id={Steps.ItemCohort}>
          <ModelCohort model={model} cohort={itemCohort} splitRatio={model.definition.population_policy.split_ratio} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Parameters} ready={true}>
        <CFTitledSection title="Params" id={Steps.Parameters}>
          <EmbeddingParamsViewer model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Runs} ready={true}>
        <CFTitledSection title="Runs" id={Steps.Runs}>
          <ModelRuns model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Schedule} ready={true}>
        <Schedule id={model.schedule.id} label="model" />
      </Wizard.Step>
    </Wizard>
  );
};

export default ModelEmbeddingDetails;
