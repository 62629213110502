import React, { useEffect, useMemo, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import {
  AppModel,
  CensoringMetricsWrap,
  EmbeddingMetrics,
  ModelId,
  ModelMetrics,
  RecommenderMetrics,
  RecommenderMetricsWrap,
} from 'domain/model.types';

import { AlgorithmClass } from 'services/intervention/intervention.types';
import { getMetrics } from 'services/model/model.repo';

import useURLModel from 'views/model/hooks/useURLModel';
import InterventionBanditMetrics from 'views/intervention/interventions/monitoring/metrics/bandit-metrics';

import CFSpinner from 'components/CFSpinner';
import { ToastType } from 'components/CFToast/types';
import RecommenderMetricsChart from './components/RecommenderMetrics';

import EmbeddingMetricsView from './embedding';
import CensoringMetricsView from './censoring';

import { Tabs } from '../../tabs';
import ModelDetailLayout from '../layout';

import colors from 'common.scss';

import useToast from 'hooks/useToast';

import './model-metrics.scss';

const ModelMetricsViewer = () => {
  const model = useURLModel();

  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [modelMetrics, setModelMetrics] = useState<ModelMetrics>();

  const { interventionService, traitSessionService } = useServicesContext();

  useEffect(() => {
    (async () => {
      if (!model) {
        return;
      }

      await fetchMetricsData(model.definition.id);

      setIsLoading(false);
    })();
  }, [model]);

  const fetchMetricsData = async (id: ModelId) => {
    if (!model) {
      return;
    }

    if (!model.definition.id || model.definition?.algo_spec?.class_name === AlgorithmClass.Bandit) {
      return;
    }

    let modelMetrics;

    try {
      modelMetrics = await getMetrics(id);

      setModelMetrics(modelMetrics);
    } catch {
      addToast('Data not ready', ToastType.INFO);

      return;
    }
  };

  const useBanditModel = useMemo(() => {
    return (
      model?.invId !== -1 &&
      (model?.definition.algo_spec.class_name === AlgorithmClass.Bandit ||
        model?.definition.algo_spec.class_name === AlgorithmClass.RestlessBandit)
    );
  }, [model]);

  if (useBanditModel) {
    return (
      <ModelDetailLayout className="model-metrics" tab={Tabs.Metrics}>
        <div className="intervention-metrics">
          <InterventionBanditMetrics
            interventionService={interventionService}
            traitService={traitSessionService}
            id={(model as AppModel).invId}
          />
        </div>
      </ModelDetailLayout>
    );
  }

  if (isLoading || !modelMetrics) {
    return (
      <ModelDetailLayout className="model-metrics" tab={Tabs.Metrics}>
        <CFSpinner size={70} color={colors.cfCyan} stroke={4} />
      </ModelDetailLayout>
    );
  }

  return (
    <ModelDetailLayout className="model-metrics" tab={Tabs.Metrics}>
      {model?.definition.algo_spec.class_name === AlgorithmClass.Recommender && (
        <RecommenderMetricsChart metrics={(modelMetrics as RecommenderMetricsWrap).recom as RecommenderMetrics} />
      )}

      {model?.definition.algo_spec.class_name === AlgorithmClass.Embedding && (
        <EmbeddingMetricsView metrics={modelMetrics as EmbeddingMetrics} />
      )}

      {model?.definition.algo_spec.class_name === AlgorithmClass.Censoring && (
        <CensoringMetricsView metrics={modelMetrics as CensoringMetricsWrap} />
      )}
    </ModelDetailLayout>
  );
};

export default ModelMetricsViewer;
