import { SeriesItem } from 'components/charts/CFScatterPlotChart';
import { PlotItem } from 'domain/model.types';
import { getColorForGroup } from 'views/intervention/interventions/monitoring/metrics/helpers';

export const generateSeries = (currentTab: string, plotItem: PlotItem): SeriesItem[] => {
  if (currentTab !== 'plot') {
    return [];
  }

  const legendItems = Array.from(new Set(plotItem.legend));
  const series = legendItems.map((legendItem, i) => ({
    name: legendItem,
    values: plotItem.legend
      .map((item, index) => {
        if (item !== legendItem) {
          return undefined;
        }

        return {
          x: plotItem.x[index],
          y: plotItem.y[index],
          id: plotItem.index[index],
        };
      })
      .filter((item) => item !== undefined),

    color: getColorForGroup(legendItem, i),
  }));

  return series as SeriesItem[];
};
