export enum DataType {
  Boolean = 'boolean',
  Bool = 'bool',
  Varchar = 'varchar',
  VarcharArray = '_varchar',
  Number = 'number',
  Int4 = 'int4',
  Int2 = 'int2',
  Float4 = 'float4',
  Timestamp = 'timestamp',
}

export enum CFRole {
  Primary = 'primary',
  Secondary = 'secondary',
  Neutral = 'neutral',
  Borderless = 'borderless',
  Cyan = 'cyan',
  OnlyIcon = 'only-icon',
}

export enum Operators {
  Equal = '=',
  NotEqual = '!=',
  LessThan = '<',
  LessOrEqualThan = '<=',
  HigherThan = '>',
  HigherOrEqualThan = '>=',
  IncludedIn = 'IN',
  NotIncludedIn = 'NOT IN',
  Is = 'IS',
  IsNot = 'IS NOT',
}

export enum Modules {
  Core = 'core',
  ECom = 'e_commerce',
  ELea = 'e_learning',
  Loyalty = 'loyalty',
  Payment = 'payment',
  PatientMgmt = 'patient_mgmt',
}

export interface GDTPoint {
  cur: number;
  prev: number;
}

export type TimeRFC3999 = string;
export type RGBColor = string;
export type Email = string;

export type Module = string;

export type SubjectId = string;
