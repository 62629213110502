import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';

import { CFRoutes } from 'routes';

import Login from 'views/login';

import { ProjectDefinition, UserDetailedInfo, UserInfo } from './types';

import { getProfile } from './auth';

import ListOfCohorts from './views/segmentation/cohorts/ListOfCohorts';
import NewCohort from './views/segmentation/cohorts/NewCohort';
import OrganizationPicker from './views/organization';
import ProjectPicker from './views/projectPicker';
import NewProject from './views/admin/projects/NewProject';

import ListOfInterventions from 'views/intervention/interventions/ListOfInterventions';

import { init as initHttpDriver } from 'repositories/drivers/http';

import Storage from 'repositories/storage.localstorage';

import { init as initTraitsService } from 'services/traits/trait.service';
import { init as initSessionService, isLoggedFor } from 'services/session/session.service';

import { getUniqueValuesForTrait as getUniqueValuesForTraitRepo } from 'services/traits/traits.repo';
import InviteUser from './views/admin/users/InviteUser';
import IntegrationApiKeyManagement from './views/integration/apikeymanagement';
import NewKey from './views/integration/apikeymanagement/NewKey';
import Traits from 'views/segmentation/traits';
import Landing from 'views/landing';

import UserDetail from 'views/admin/users/UserDetail';
import NewOrganization from 'views/admin/orgs/NewOrganization';

import CFHeader from 'connected-components/CFHeader';

import {
  clearSession as sessionClear,
  setDetailedUserInfo,
  getOrganization,
  getProject,
  getJWTToken,
  setOrganization,
  setProject,
  setJWTToken,
  getUserInfo,
} from 'services/session/session.service';

import EditProject from 'views/admin/projects/EditProject';
import ListOfNudges from 'views/intervention/nudges/ListOfNudges';
import { NewNudgeView } from 'views/intervention/nudges/NewNudge';
import Analytics from 'views/analytics/explore';
import InterventionDefinition from 'views/intervention/interventions/monitoring/definition';
import IntegrationMonitoring from 'views/integration/monitoring';
import IntegrationIngestHistory from 'views/integration/ingesthistory';
import IntegrationCatalogHistory from 'views/integration/cataloghistory';
import IntegrationExceptionView from 'views/integration/exceptionview';

import InterventionMetrics from 'views/intervention/interventions/monitoring/metrics';

import ToastProvider from 'context/ToastProvider';

import TraitDefinitions from 'views/traitDefinitions/traits';
import TraitBackillStatus from 'views/traitDefinitions/backfill';

import NudgeAdoption from 'views/intervention/adoption/creation';
import ListAdoptedNudges from 'views/intervention/adoption/list';
import ModelList from 'views/model/list';

import Intervention from 'views/intervention/interventions/NewIntervention';
import NewModel from 'views/model/creation';
import ModelDefinition from 'views/model/detail/definition';
import { useServicesContext } from 'hooks/useServicesContext';
import ModelMetricsViewer from 'views/model/detail/metrics';
import CFSpinner from 'components/CFSpinner';

import ModelMlTraits from 'views/model/detail/mlTraits';
import ActiveUsersPerRole from 'views/analytics/userEngagement/ActiveUsersPerRole';
import PercentageOfActiveUsers from 'views/analytics/userEngagement/PercentageOfActiveUsers';
import PatientEncountersPerActiveUsers from 'views/analytics/userEngagement/PatientEncountersPerActiveUsers';

import useCFNavigation from 'hooks/useCFNavigation';
import Assistant from 'views/assistant/index';

import { IconMode } from 'views/assistant/AssistantWall/components/Message/ProfileIcon';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import ChatSwitcher from 'views/assistant/AssistantWall/components/Message/ChatSwitcher';

import colors from 'common.scss';

import ListOfUsers from 'views/admin/users/ListOfUsers';
import ListOfProjects from 'views/admin/projects/ListOfProjects';
import ListOfOrgs from 'views/admin/orgs/ListOfOrgs';
import InternalAdminTab from 'views/admin/internal';

import './App.scss';
import Upload from 'views/upload';
import NudgeAdoptionDetails from 'views/intervention/adoption/details';

interface ProtectedRouteProps {
  user: UserInfo;
  children: ReactElement;
}

const ProtectedRoute = ({ user, children }: ProtectedRouteProps) => {
  if (user.token === '') {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};
console.log('running!!!');

const App = () => {
  const navigate = useCFNavigation();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [showChat, setShowChat] = useState(false);
  const [searchParams] = useSearchParams();
  const { ready, init } = useServicesContext();

  const [validatingToken, setValidatingToken] = useState(true);
  const [projects, setProjects] = useState<ProjectDefinition[]>([]);

  const [assistantWidth, setAssistantWidth] = useState(0);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: '',
    email: '',
    token: '',
  });

  const initRepositories = async () => {
    const orgId = parseInt(getOrganization() || '');
    const projectId = parseInt(getProject() || '');
    const token = getJWTToken() || '';

    await init({ token, oid: orgId, pid: projectId });
    initTraitsService(getUniqueValuesForTraitRepo);
  };

  const clearSession = () => {
    sessionClear();

    setUserInfo({
      name: '',
      email: '',
      token: '',
    });
  };

  useEffect(() => {
    if (!chatContainerRef || !chatContainerRef.current) {
      return;
    }

    setAssistantWidth(chatContainerRef.current.getBoundingClientRect().width);
  }, [chatContainerRef, chatContainerRef.current, showChat]);

  const assistantSwitcherRight = useMemo(() => {
    const defaultPadding = 32;

    if (!showChat) {
      return defaultPadding;
    } else {
      return assistantWidth + defaultPadding;
    }
  }, [showChat, assistantWidth]);

  const loginOK = useCallback(
    async (userDetailedInfo: UserDetailedInfo, token: string) => {
      setUserInfo({
        name: userDetailedInfo.user.name,
        email: userDetailedInfo.user.username,
        token,
      });

      initHttpDriver(() => {
        clearSession();
        navigate('/login');
      });

      setDetailedUserInfo(userDetailedInfo);

      const urlOrg = searchParams.get('oid');
      const urlProj = searchParams.get('pid');

      if (urlOrg && urlProj) {
        if (!isLoggedFor(parseInt(urlOrg), parseInt(urlProj))) {
          clearSession();
          navigate('/login');
        } else {
          setProject(urlProj);
          setOrganization(urlOrg);
        }
      }

      // if current organization does not exist in user info, go back to org picker
      const org = getOrganization();
      const proj = getProject();

      const currentOrg = userDetailedInfo.available_orgprojs.find((orgproj) => `${orgproj.org.id}` === `${org}`);

      if (org !== null && !currentOrg) {
        clearSession();
        navigate('/org');
      }

      if (proj !== null && !currentOrg?.projs.find((proj) => `${proj.id}` === getProject())) {
        clearSession();
        navigate('/org');
      }

      setJWTToken(token);
      setValidatingToken(false);

      if (getProject() === null) {
        navigate('/org');
      } else {
        await initRepositories();
      }
    },
    [searchParams]
  );

  useEffect(() => {
    initSessionService(Storage);
  }, []);

  useEffect(() => {
    getProfile(process.env.REACT_APP_SERVER_BASE_URL || '')
      .then(async (userDetailedInfo: UserDetailedInfo) => {
        await loginOK(userDetailedInfo, getJWTToken() || '');
      })
      .catch(() => {
        setValidatingToken(false);

        navigate('/login');
      });
  }, []);

  const handleSwitchChat = useCallback(() => {
    setShowChat((showChat) => !showChat);
  }, []);
  const onSelectedOrganization = (id: string) => {
    setOrganization(id);

    const currentOrganization = getUserInfo()?.available_orgprojs.find((orgproj) => orgproj.org.id === parseInt(id));

    setProjects(currentOrganization?.projs || []);

    navigate('/project');
  };

  const onSelectedProject = async (id: string) => {
    setProject(id);

    await initRepositories();
    navigate('/');
  };

  const loginError = () => {
    console.log('login error!');
  };

  const handleLogout = () => {
    clearSession();

    navigate('/');
  };

  if (validatingToken) {
    return <div></div>;
  }

  if (!userInfo.token) {
    return (
      <div className="App-login">
        <Login serverBaseUrl={process.env.REACT_APP_SERVER_BASE_URL as string} onError={loginError} onLogin={loginOK} />
      </div>
    );
  }

  const handleOrgProjChange = (orgId: number, projId: number) => {
    setProject(`${projId}`);
    setOrganization(`${orgId}`);

    navigate('/');
    // this should be replaced by global state management
    location.reload();
  };

  const currentOrgInfo = getUserInfo().available_orgprojs.find(
    (orgproj) => orgproj.org.id === parseInt(getOrganization() || '')
  );
  const currentProject = currentOrgInfo?.projs.find((proj) => proj.id === parseInt(getProject() || ''));

  if (getProject() === null) {
    return (
      <div className={classNames('App', { 'with-chat': showChat })}>
        <div className="header">
          <CFHeader
            handleOrgProjChange={handleOrgProjChange}
            currentProject={currentProject}
            handleLogout={handleLogout}
            completed={false}
          />
        </div>

        <div className="main">
          <Routes>
            <Route
              path="login"
              element={
                <Login
                  serverBaseUrl={process.env.REACT_APP_SERVER_BASE_URL as string}
                  onError={loginError}
                  onLogin={loginOK}
                />
              }
            />

            <Route
              path="org"
              element={
                <ProtectedRoute user={userInfo}>
                  <OrganizationPicker
                    onSelect={onSelectedOrganization}
                    orgs={getUserInfo()?.available_orgprojs.map((item) => item.org) || []}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="project"
              element={
                <ProtectedRoute user={userInfo}>
                  <ProjectPicker onSelect={onSelectedProject} projects={projects} />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
    );
  }

  const routes = [
    {
      path: CFRoutes.upload,
      Component: Upload,
    },
    {
      path: CFRoutes.model,
      Component: ModelList,
    },
    {
      path: CFRoutes.model_new,
      Component: NewModel,
    },
    {
      path: CFRoutes.model_monitoring_definition,
      Component: ModelDefinition,
    },
    {
      path: CFRoutes.model_monitoring_metrics,
      Component: ModelMetricsViewer,
    },
    {
      path: CFRoutes.model_mltraits,
      Component: ModelMlTraits,
    },
    {
      path: CFRoutes.intervention_monitoring_definition,
      Component: InterventionDefinition,
    },
    {
      path: CFRoutes.intervention_monitoring_metrics,
      Component: InterventionMetrics,
    },
    {
      path: CFRoutes.intervention,
      Component: ListOfInterventions,
    },
    {
      path: CFRoutes.traits_definition,
      Component: TraitDefinitions,
    },
    {
      path: CFRoutes.traits_backfill_status,
      Component: TraitBackillStatus,
    },
    {
      path: CFRoutes.intervention_nudge_adoption_new,
      Component: NudgeAdoption,
    },
    {
      path: CFRoutes.intervention_nudge_adoption,
      Component: ListAdoptedNudges,
    },
    {
      path: CFRoutes.intervention_nudge_adoption_details,
      Component: NudgeAdoptionDetails,
    },
    {
      path: CFRoutes.intervention_new,
      Component: Intervention,
    },
    { path: CFRoutes.intervention_nudge_list, Component: ListOfNudges },
    {
      path: CFRoutes.intervention_nudge_new,
      Component: NewNudgeView,
    },
    {
      path: CFRoutes.analytics,
      Component: Analytics,
    },
    {
      path: CFRoutes.analytics_explore,
      Component: Analytics,
    },
    {
      path: CFRoutes.analytics_engagement_per_role,
      Component: ActiveUsersPerRole,
    },
    {
      path: CFRoutes.analytics_engagement_percentage,
      Component: PercentageOfActiveUsers,
    },
    {
      path: CFRoutes.analytics_engagement_patient_encounters,
      Component: PatientEncountersPerActiveUsers,
    },
    {
      path: CFRoutes.integration,
      Component: IntegrationMonitoring,
    },
    { path: CFRoutes.integration_ingest_history, Component: IntegrationIngestHistory },
    {
      path: CFRoutes.integration_catalog_history,
      Component: IntegrationCatalogHistory,
    },
    { path: CFRoutes.integration_exception_view, Component: IntegrationExceptionView },
    { path: CFRoutes.integration_keys, Component: IntegrationApiKeyManagement },
    { path: CFRoutes.project_edit, Component: EditProject },
    {
      path: CFRoutes.segmentation_cohort_new,
      Component: NewCohort,
    },
    {
      path: CFRoutes.segmentation_cohort,
      Component: ListOfCohorts,
    },
    { path: CFRoutes.segmentation_trait, Component: Traits },
    { path: CFRoutes.project_new, Component: NewProject },
    { path: CFRoutes.new_org, Component: NewOrganization },
    { path: CFRoutes.assistant, Component: Assistant },
  ];

  const projectId = getProject();
  const orgId = getOrganization();

  if (!ready) {
    return (
      <div className="app-spinner-wrapper">
        <CFSpinner size={70} color={colors.cfCyan} stroke={4} />
      </div>
    );
  }

  return (
    <div className={classNames('App', { 'with-chat': showChat && isAllowedTo(AuthAction.SeeAssistantEmbedded) })}>
      <ToastProvider>
        <div className="header">
          <CFHeader
            handleOrgProjChange={handleOrgProjChange}
            currentProject={currentProject}
            handleLogout={handleLogout}
            completed={true}
          />
        </div>

        {showChat && isAllowedTo(AuthAction.SeeAssistantEmbedded) && (
          <div ref={chatContainerRef} className="chat">
            <Assistant embedded={true} />
          </div>
        )}

        <div className="main">
          {isAllowedTo(AuthAction.SeeAssistantEmbedded) && (
            <div onClick={handleSwitchChat} className="sidepanel-switcher" style={{ right: assistantSwitcherRight }}>
              <ChatSwitcher className="sidepanel-switcher__open" mode={showChat ? IconMode.Light : IconMode.Dark} />
            </div>
          )}

          <Routes>
            <Route index element={<Landing />} />

            <Route
              path="keys"
              element={
                <ProtectedRoute user={userInfo}>
                  <IntegrationApiKeyManagement />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.integration_keys_new}
              element={
                <ProtectedRoute user={userInfo}>
                  <NewKey userDetailedInfo={getUserInfo()} projectId={parseInt(projectId || '')} />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.invite_user}
              element={
                <ProtectedRoute user={userInfo}>
                  <InviteUser userDetailedInfo={getUserInfo()} orgId={parseInt(orgId || '')} />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.user_detail}
              element={
                <ProtectedRoute user={userInfo}>
                  <UserDetail userDetailedInfo={getUserInfo()} orgId={parseInt(orgId || '')} />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.admin}
              element={
                <ProtectedRoute user={userInfo}>
                  <ListOfUsers />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.admin_org}
              element={
                <ProtectedRoute user={userInfo}>
                  <ListOfOrgs />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.admin_project}
              element={
                <ProtectedRoute user={userInfo}>
                  <ListOfProjects />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.admin_users}
              element={
                <ProtectedRoute user={userInfo}>
                  <ListOfUsers />
                </ProtectedRoute>
              }
            />

            <Route
              path={CFRoutes.admin_internal}
              element={
                <ProtectedRoute user={userInfo}>
                  <InternalAdminTab />
                </ProtectedRoute>
              }
            />
            {routes.map(({ path, Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ProtectedRoute user={userInfo}>
                    <Component />
                  </ProtectedRoute>
                }
              />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </ToastProvider>
    </div>
  );
};

export default App;
