import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { overlaps } from 'helpers/dates';

import { Schedule, ScheduleType, StartEndPair } from 'services/scheduling/schedulting.types.api';

import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';

import Month from './month';

import CalendarSpecs from './CalendarSpecs';
import { SchedulingBuilderRef } from '.';

import { useSchedulingContext } from './context/useSchedulingContext';

import CalendarTypeSelector from './CalendarTypeSelector';
import RecurringSpecs from './RecurringSpecs';

import './scheduling.scss';

interface Props {
  defaultValue?: Schedule;
  editing?: boolean;
  onReady: (ready: boolean) => void;
}

function isValidOffset(offset: string) {
  const regex = /^[+-]([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/; // Expresión regular para +hh:mm
  return regex.test(offset);
}

const SchedulingBuilderInternal = forwardRef<SchedulingBuilderRef, Props>(function InterventionScheduling(
  { editing = false, onReady, defaultValue }: Props,
  ref
) {
  const today = new Date();

  const { startTime, calendarDates, toggleCalendarDate, scheduleType, timezone, scheduleSetValues } =
    useSchedulingContext();

  const [currentMonth, setCurrentMonth] = useState<Date>(today);
  const [hoverDay, setHoverDay] = useState<Date>();

  const onDayClick = useCallback(
    (day: Date) => {
      if (scheduleType === ScheduleType.Recurring) {
        return;
      }

      toggleCalendarDate(day);
    },
    [scheduleType, calendarDates]
  );

  const onDayHover = useCallback(
    (date: Date) => {
      if (!hoverDay || !dayjs(date).isSame(hoverDay)) {
        setHoverDay(date);
      }
    },
    [hoverDay]
  );

  const onMonthNavigate = useCallback(
    (action: NavigationAction) => {
      const newMonth = dayjs(currentMonth).add(action, 'month').toDate();
      setCurrentMonth(newMonth);
    },
    [currentMonth]
  );

  const isHover = useCallback(
    (day: Date): boolean => {
      return !!hoverDay && dayjs(hoverDay).isSame(day, 'day');
    },
    [hoverDay, defaultValue]
  );

  const helpers = useMemo(() => ({ isHover }), [isHover]);

  const value = useMemo(() => {
    let formattedOffset: string;

    if (isValidOffset(timezone)) {
      formattedOffset = timezone;
    } else {
      const dateInTimezone = dayjs().tz(timezone);

      const offsetInMinutes = dateInTimezone.utcOffset();

      const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60)
        .toString()
        .padStart(2, '0');
      const offsetMinutes = Math.abs(offsetInMinutes % 60)
        .toString()
        .padStart(2, '0');
      const offsetSign = offsetInMinutes >= 0 ? '+' : '-';
      formattedOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`;
    }

    return {
      type: scheduleType,
      definition: {
        tz: formattedOffset,
        ...(scheduleType === ScheduleType.Recurring && {
          recurring: { pairs: Object.values(scheduleSetValues) },
        }),
        ...(scheduleType === ScheduleType.TimePoints && {
          time_points: {
            pts: calendarDates
              .map((date) => `${date.slice(0, 'YYYY-MM-DDT'.length)}${startTime}:00${formattedOffset}`)
              .sort(),
          },
        }),
      },
    } as Schedule;
  }, [scheduleType, scheduleSetValues, calendarDates, timezone, startTime]);

  useEffect(() => {
    const isReady =
      (value.type === ScheduleType.TimePoints && value.definition.time_points?.pts.length !== 0) ||
      (value.type === ScheduleType.Recurring &&
        value.definition.recurring?.pairs.length !== 0 &&
        !overlaps(
          value.definition.recurring?.pairs.filter((schedule): schedule is StartEndPair => schedule !== null) || []
        ));

    onReady(isReady);
  }, [value]);

  useImperativeHandle(ref, () => ({
    value: () => value,
  }));

  const minDateValid = dayjs(today).subtract(10, 'years').toDate();
  const maxDateValid = dayjs(today).add(10, 'years').toDate();

  return (
    <>
      <CalendarTypeSelector defaultValue={defaultValue} />

      <div className="scheduling-builder">
        <div className="scheduling-builder-calendar">
          <Month
            timeOffset={defaultValue?.definition.tz || '0'}
            selectedDate={today}
            minDate={minDateValid}
            maxDate={maxDateValid}
            onDayClick={onDayClick}
            onDayHover={onDayHover}
            onMonthNavigate={onMonthNavigate}
            helpers={helpers}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            highlightedDates={calendarDates.map((timestamp) => ({
              date: dayjs(timestamp).toDate(),
            }))}
          />
        </div>

        {scheduleType === ScheduleType.Recurring ? (
          <RecurringSpecs defaultValue={defaultValue} editing={editing} />
        ) : (
          <CalendarSpecs editing={editing} />
        )}
      </div>
    </>
  );
});

export default SchedulingBuilderInternal;
