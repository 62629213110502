import React from 'react';
import classNames from 'classnames';

import './cf-button-group.scss';

export interface ButtonGroupOption {
  label: string;
  value: any;
}

interface Props {
  options: ButtonGroupOption[];
  value: ButtonGroupOption;
  className?: string;
  onSelect: (option: ButtonGroupOption) => void;
}

const CFButtonGroup = ({ options, value, className, onSelect }: Props): JSX.Element => (
  <div className={classNames('cf-button-group', className)}>
    {options.map((option) => (
      <div
        key={option.value}
        data-testid={`option_${option.value}_${option.value === value.value}`}
        className={classNames('cf-button-group-button', { active: option.value === value.value })}
        onClick={() => onSelect(option)}
      >
        {option.label}
      </div>
    ))}
  </div>
);

export default CFButtonGroup;
