import StatusTag, { StatusTagVariant } from 'components/StatusTag';
import React, { useMemo } from 'react';

import useIntervention from 'services/intervention/hooks/useIntervention';
import { AlgorithmSpec, InterventionId, InterventionStatus } from 'services/intervention/intervention.types';

import './intervention-summary.scss';

interface Props {
  id: InterventionId;
}

const InterventionSummary = ({ id }: Props) => {
  const intervention = useIntervention(id);

  const title = useMemo(() => {
    if (!intervention) {
      return '';
    }

    return `#${intervention.intervention.id}: ${intervention.intervention.name}`;
  }, [intervention]);

  const statusVariantMap = {
    [InterventionStatus.Finished]: StatusTagVariant.Success,
    [InterventionStatus.Running]: StatusTagVariant.InProgress,
    [InterventionStatus.Terminated]: StatusTagVariant.Terminated,
    [InterventionStatus.Paused]: StatusTagVariant.Paused,
    [InterventionStatus.Pending]: StatusTagVariant.Pending,
  };

  return (
    <div className="intervention-summary">
      <span> {`Intervention ${title}`} </span>
      <span>{(intervention?.intervention.algo_policy?.spec as AlgorithmSpec)?.algo_name}</span>
      <span> Model {intervention?.intervention.algo_policy?.model_def_id} </span>
      <span>
        <StatusTag
          label={intervention?.status || ''}
          variant={statusVariantMap[intervention?.status as InterventionStatus]}
        />
      </span>
    </div>
  );
};

export default InterventionSummary;
