import React, { forwardRef } from 'react';

import { useEffect, CSSProperties } from 'react';
import { CanvasRenderer } from 'echarts/renderers';
import { init, getInstanceByDom, use } from 'echarts/core';
import { LineChart, BarChart, BoxplotChart } from 'echarts/charts';

import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  TitleComponent,
  DataZoomComponent,
} from 'echarts/components';

import type { ECharts, ComposeOption, SetOptionOpts } from 'echarts/core';
import type {
  BarSeriesOption,
  LineSeriesOption,
  BoxplotSeriesOption,
  ScatterSeriesOption,
  GraphSeriesOption,
} from 'echarts/charts';
import type { TitleComponentOption, GridComponentOption } from 'echarts/components';
import { graphic } from 'echarts';

// Register the required components
use([
  LegendComponent,
  BoxplotChart,
  LineChart,
  BarChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  ToolboxComponent, // A group of utility tools, which includes export, data view, dynamic type switching, data area zooming, and reset.
  DataZoomComponent, // Used in Line Graph Charts
  CanvasRenderer, // If you only need to use the canvas rendering mode, the bundle will not include the SVGRenderer module, which is not needed.
]);

// Combine an Option type with only required components and charts via ComposeOption
export type EChartsOption = ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | BoxplotSeriesOption
  | ScatterSeriesOption
  | GraphSeriesOption
>;

export const LinearGradient = graphic.LinearGradient;

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: 'light' | 'dark';
}

export const ReactECharts = forwardRef<HTMLDivElement, ReactEChartsProps>(function ReactECharts(
  { option, style, settings, loading, theme }: ReactEChartsProps,
  chartRef
): JSX.Element {
  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if ((chartRef as any).current !== null) {
      chart = init((chartRef as any).current);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, [theme, style]);

  useEffect(() => {
    // Update chart
    if ((chartRef as any).current !== null) {
      const chart = getInstanceByDom((chartRef as any).current);
      chart?.setOption({ ...option /*Color: colors.cardBackground*/ }, settings);
    }
  }, [option, settings, theme]);

  useEffect(() => {
    if ((chartRef as any).current !== null) {
      const chart = getInstanceByDom((chartRef as any).current);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (loading) {
        chart?.showLoading({
          textColor: '#fff',
          maskColor: 'rgba(0, 0, 0, 0.1)',
          color: '#fff',
        });
      } else {
        chart?.hideLoading();
      }
    }
  }, [loading, theme]);

  return <div ref={chartRef} className="echart-instance" style={{ width: '100%', ...style }} />;
});
