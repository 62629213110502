import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { Option } from 'components/CFSelect';

import './item.scss';

export interface Props {
  option: Option;
  active: boolean;
  focused?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  onClick?: (option: Option) => void;
}

const CohortItem = forwardRef<HTMLDivElement, Props>(function Item(
  { option, active, onClick, focused = false }: Props,
  ref
) {
  return (
    <div
      ref={ref}
      role="option"
      key={option.value}
      className={classNames('cf-select-cohort-item', { active, focused })}
      onClick={() => onClick?.(option)}
    >
      <div className="cf-select-cohort-item__title">
        {option.label} ({option.meta.size} {option.meta.unit}
        {option.meta.size > 1 ? 's' : ''})
      </div>
      <div className="cf-select-cohort-item__description"> {option.meta.description}</div>
    </div>
  );
});

export default CohortItem;
