import React from 'react';

import classNames from 'classnames';

import './wizard-steps.scss';

export enum StepStatus {
  Ready = 'ready',
  Ongoing = 'ongoing',
  Pending = 'pending',
}

export interface WizardStep {
  name: string;
  targetSection: string;
  status: StepStatus;
}

interface Props {
  steps: WizardStep[];
  top: number;
  className?: string;
}

const CFWizardSteps = ({ steps, top, className }: Props) => {
  return (
    <div className={classNames('wizard-steps', className)} style={{ top, opacity: top ?? 1 }}>
      {steps.map((step, i) => (
        <React.Fragment key={step.name}>
          <a href={`#${step.targetSection}`}>
            <div className="step-row" key={step.name}>
              <div className={`step ${step.status}`}> {i + 1}</div>
              <div className={`step-name ${step.status}`}> {step.name} </div>
            </div>
          </a>
          {i !== steps.length - 1 && <div className="line"></div>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CFWizardSteps;
