import { ToastType } from 'components/CFToast/types';
import { TraitSubject } from 'domain/traits.types';
import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';
import { useEffect, useState } from 'react';
import Cohort from '../domain/Cohort';

const COHORT_PAGE_SIZE = 100;

const useCohortList = (subject: TraitSubject) => {
  const { cohortService } = useServicesContext();
  const [listOfCohorts, setListOfCohorts] = useState<Cohort[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    cohortService
      .getListOfCohorts(0, COHORT_PAGE_SIZE, subject)
      .then((cohortList) => {
        // TODO: handling pagination?? (if needed)
        setListOfCohorts(cohortList.data);
      })
      .catch((err) => {
        addToast((err as any).message, ToastType.ERROR);
        console.log('Error geting cohorts');
      });
  }, [subject]);

  return listOfCohorts;
};

export default useCohortList;
