import dayjs from 'dayjs';

import { AggLevel } from 'domain/stats.types';
import { TraitCategory, TraitSubject, TraitType } from 'domain/traits.types';
import { TimeRFC3999 } from 'domain/general.types';

import { CohortID } from 'services/cohort/cohort.types';
import { Ptr } from 'services/cohort/cohort.types.api';
import CFService from 'services/cfservice';
import { CFTraitRepository } from 'services/traits/traits.repo';

import { startBackfilling, stopBackfilling } from '../session/session.service';

import { pruneHoursNoTimezone } from 'helpers/dates';
import { BackfillReference } from './backfill.types.api';

const BACKFILLING_INTERVAL = 2000;

const formatBackfillDate = (date: TimeRFC3999, dayOffset: number) => {
  return pruneHoursNoTimezone(dayjs(date).add(dayOffset, 'day').toDate());
};

export default class BackfillService extends CFService {
  traitRepository: CFTraitRepository;

  constructor(traitRepository: CFTraitRepository) {
    super();
    this.traitRepository = traitRepository;
  }

  init = async () => {
    this.list();
  };

  list = async () => {
    try {
      const backfills = await this.traitRepository.listCurrentBackfills();
      return backfills;
    } catch {
      return [];
    }
  };

  backfill = async (
    start: TimeRFC3999,
    end: TimeRFC3999,
    aggLevel: AggLevel,
    subjectType: TraitSubject,
    tableType: TraitType,
    cohortIDs: CohortID[],
    recomputeSubject: boolean
  ) => {
    const extendedStartDate = formatBackfillDate(start, 0);
    const extendedEndDate = formatBackfillDate(end, 1);

    startBackfilling();

    try {
      await this.traitRepository.backfill(
        extendedStartDate,
        extendedEndDate,
        aggLevel,
        subjectType,
        tableType,
        cohortIDs,
        recomputeSubject
      );
    } catch {
      throw new Error('backfill-error');
    } finally {
      stopBackfilling();
    }
  };

  backfillTrait = async (
    start: TimeRFC3999 | undefined,
    end: TimeRFC3999 | undefined,
    cohortIDs: CohortID[],
    recomputeSubject: boolean,
    ptr: Ptr
  ) => {
    const extendedStartDate = start ? formatBackfillDate(start, 0) : undefined;
    const extendedEndDate = end ? formatBackfillDate(end, 0) : undefined;

    const waitForBackfilling = (ptr: Ptr, timeout: number) => {
      return new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            const backfillingStatus = await this.checkBackfillingState(ptr);

            if (backfillingStatus === null) {
              clearInterval(intervalId);
              resolve(null);
            }
          } catch (error) {
            clearInterval(intervalId);
            reject(error);
          }
        }, timeout);
      });
    };

    try {
      await this.traitRepository.backfillTrait(extendedStartDate, extendedEndDate, cohortIDs, recomputeSubject, ptr);

      await waitForBackfilling(ptr, BACKFILLING_INTERVAL);
    } catch (e) {
      console.log('backfilling error: ', e);
      throw new Error('backfilling-error');
    }
  };

  checkBackfillingState = async (ptr: Ptr) => {
    try {
      const data = await this.traitRepository.checkBackfillingState(ptr);
      return data;
    } catch {
      throw new Error('backfill-check-error');
    }
  };

  getReferenceDetail = async (ref: BackfillReference) => {
    try {
      const data = await this.traitRepository.checkBackfillingReference(ref);
      return data;
    } catch {
      throw new Error('backfill-check-reference-error');
    }
  };

  cancel = async (ref: BackfillReference) => {
    await this.traitRepository.cancelBackfill(ref);
  };

  isBackfillable = (category: TraitCategory) => {
    return (
      category === TraitCategory.Dynamic || category === TraitCategory.Grouped || category === TraitCategory.Static
    );
  };
}
