import React from 'react';

import { EmbeddingDataSpec, ModelId } from 'domain/model.types';

import useModel from 'views/model/hooks/useModel';
import KeyValue from 'views/intervention/components/KeyValue';

interface Props {
  id: ModelId;
}

const EmbeddingModelSummary = ({ id }: Props) => {
  const model = useModel({ id });

  if (!model) {
    return <div></div>;
  }

  return (
    <div>
      <KeyValue name="Name" value={model.definition.name} />
      <KeyValue name="Algorithm" value={model.definition.algo_spec.algo_name} />

      <KeyValue
        name={'Interaction Type'}
        value={(model.definition.data_defn.embedding as EmbeddingDataSpec).interaction_type}
      />
    </div>
  );
};

export default EmbeddingModelSummary;
