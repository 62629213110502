import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react';

import { ExpandableScatterPlot } from 'components/charts/CFScatterPlotChart';
import { ScatterPlot } from 'domain/analytic.types';
import { AlgorithmClass } from 'domain/model.types';
import { getLifetimePlots } from 'services/model/censoring.repo';
import useURLModel from 'views/model/hooks/useURLModel';
import CFInput, { CFInputType } from 'components/CFInput';
import debounce from 'debounce';
import CFSpinner from 'components/CFSpinner';

interface Props {
  className?: string;
}

const LifetimeChart = ({ className }: Props) => {
  const model = useURLModel();

  const [lifetime, setLifetime] = useState<ScatterPlot>();
  const [nextDays, setNextDays] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!model || model.definition.algo_spec.class_name !== AlgorithmClass.Censoring) {
        return;
      }

      if (isNaN(nextDays)) {
        return;
      }
      const lifetime = await getLifetimePlots(model.definition.id, nextDays);
      setLifetime(lifetime);
      setLoading(false);
    })();
  }, [model, nextDays]);

  const handleLifetimeChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setNextDays(parseInt(evt.target.value));
  };

  const debouncedHandleLifetimeChange = useCallback(debounce(handleLifetimeChange, 300), []);

  useEffect(() => {
    setLoading(true);
  }, [nextDays]);

  const series = useMemo(() => {
    if (!lifetime) {
      return [];
    }

    return [
      {
        name: 'lifetime',
        values: lifetime.x.map((value, i) => ({ x: value, y: lifetime.y[i], id: '' })),
      },
    ];
  }, [lifetime]);

  return (
    <ExpandableScatterPlot
      title={'Event probability vs observed lifetime'}
      className={className}
      description={'Shows the prob. of event in the next d days vs observed lifetime for censored users'}
      series={series}
      xLabel={'Current lifetime (in days) '}
      yLabel={`Prob (${nextDays} days)`}
      step={1}
      square={false}
      size={500}
    >
      {loading && <CFSpinner />}
      <CFInput type={CFInputType.Number} onChange={debouncedHandleLifetimeChange} defaultValue={nextDays} />
    </ExpandableScatterPlot>
  );
};

export default LifetimeChart;
