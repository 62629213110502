import React, { useMemo } from 'react';

import { round, timeWindowToHuman } from 'helpers/misc';

import { BanditMetrics, InterventionViewExtended } from 'services/intervention/intervention.types';
import TraitService from 'services/traits/traitSession.service';
import { getIdentifier } from 'services/traits/helpers.traits';

import KeyValue from 'views/intervention/components/KeyValue';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import CFTitledSection from 'components/CFTitledSection';

import './metrics.scss';
import { Steps } from 'views/intervention/interventions/NewIntervention';

interface Props {
  intervention: InterventionViewExtended;
  traitService: TraitService;
  step: Steps;
  className?: string;
}

const BanditMetricsViewer = ({ intervention, traitService, step, className = '' }: Props) => {
  const [timeWindowValue, timeWindowUnit] = useMemo(() => {
    return timeWindowToHuman((intervention.intervention.metric_policy.bandit as BanditMetrics).decision_time_window);
  }, [(intervention.intervention.metric_policy.bandit as BanditMetrics).decision_time_window]);

  const objective =
    intervention.intervention.metric_policy.bandit &&
    traitService.getTraitFromAddr(intervention.intervention.metric_policy.bandit.objective);
  const supporting =
    intervention.intervention.metric_policy.bandit?.supporting
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  const staticCtx = intervention.intervention.metric_policy.bandit?.static_ctx || [];
  const dynamicCtx = intervention.intervention.metric_policy.bandit?.dynamic_ctx || [];

  const contextual =
    [...staticCtx, ...dynamicCtx]
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  return (
    <CFTitledSection title="Metrics" className={`metrics-definition metrics ${className}`} id={step}>
      <div className="metrics-section">
        <div className="metrics-section-title">Primary Metric</div>
        {objective && (
          <div className="trait-list-item">
            <div className="trait-list-item-element">
              <CFTraitItem addr={objective.addr} />
            </div>
            <div className="trait-list-item-element">{objective.addr.dtype}</div>
            <div className="trait-list-item-element">{objective.meta.description}</div>
          </div>
        )}
      </div>

      <div className="metrics-section">
        <div className="metrics-section-title">Supporting metrics</div>
        {supporting.map((trait) => {
          return (
            <div key={getIdentifier(trait)} className="trait-list-item">
              <div className="trait-list-item-element">
                <CFTraitItem addr={trait.addr} />
              </div>
              <div className="trait-list-item-element">{trait.addr.dtype}</div>
              <div className="trait-list-item-element">{trait.meta.description}</div>
            </div>
          );
        })}
      </div>

      <div className="metrics-section">
        <div className="metrics-section-title">Contextual metrics</div>
        {contextual.map((trait) => {
          return (
            <div key={getIdentifier(trait)} className="trait-list-item">
              <div className="trait-list-item-element">
                <CFTraitItem addr={trait.addr} />
              </div>
              <div className="trait-list-item-element">{trait.addr.dtype}</div>
              <div className="trait-list-item-element">{trait.meta.description}</div>
            </div>
          );
        })}
      </div>

      <KeyValue name={'Monitoring window'} value={`${parseFloat(round(timeWindowValue, 3))} ${timeWindowUnit}`} />
    </CFTitledSection>
  );
};

export default BanditMetricsViewer;
