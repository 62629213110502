import React from 'react';

import dayjs from 'dayjs';

import CFSelect from 'components/CFSelect';
import CFChip from 'components/chips/CFChip';
import TimePicker from 'components/DateTime/TimePicker';
import { useSchedulingContext } from '../context/useSchedulingContext';

import timezones from '../timezones.json';

interface Props {
  editing: boolean;
}

const CalendarSpecs = ({ editing }: Props) => {
  const { calendarDates, toggleCalendarDate, timezone, setTimezone, startTime, setStartTime } = useSchedulingContext();

  return (
    <div className="scheduling-builder-calendar">
      <div className="set-title">Selected Dates</div>
      <div className="selected-dates" data-testid="ttt123">
        {calendarDates.map((date) => (
          <CFChip
            key={date}
            value={dayjs(date).format('ddd DD-MM-YY')}
            removable={(editing && new Date() < new Date(date)) || !editing}
            onRemove={() => toggleCalendarDate(new Date(date))}
          />
        ))}
      </div>

      <div className="group timezone calendar">
        <div className="text-md">Timezone</div>
        <CFSelect
          options={Object.entries(timezones).map(([k, v]) => ({ value: v, label: k }))}
          value={{ value: timezone, label: timezone }}
          onSelected={(option) => setTimezone(option.value)}
          disabled={editing}
          testId={'timezone-picker-select'}
        />
      </div>
      <div className="group">
        <div className="text-md">Start time</div>
        <TimePicker
          defaultValue={startTime}
          onChange={(time) => {
            setStartTime(time);
          }}
          disabled={editing}
        />
      </div>
    </div>
  );
};

export default CalendarSpecs;
