import React, { useEffect, useState } from 'react';

import { NudgeStat } from 'services/nudging/nudge.types';
import { stats as getNudgeStats } from 'services/intervention/intervention.repo';
import { InterventionId } from 'services/intervention/intervention.types';

import NudgeStats from 'views/intervention/components/nudges/NudgeStats';

interface Props {
  id: InterventionId;
}

const InterventionNudgeStats = ({ id }: Props) => {
  const [nudgeStats, setNudgeStats] = useState<NudgeStat>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const stats = await getNudgeStats(id);
      setNudgeStats(stats);
      setIsLoading(false);
    })();
  }, []);

  return <NudgeStats stats={nudgeStats} isLoading={isLoading} />;
};

export default InterventionNudgeStats;
