import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { Model, PtrWithWindows } from 'domain/model.types';

import Cohort from 'services/cohort/domain/Cohort';
import { AlgorithmName } from 'services/intervention/intervention.types';
import { getTraitNameFromAddr } from 'services/traits/helpers.traits';

import { Steps } from 'views/model/creation/useContext';

import Schedule from 'connected-components/schedule';
import CFTraitItem from 'connected-components/traits/CFTraitItem';
import CFWindowsInfo from 'connected-components/traits/CFWindowsInfo';
import CFLink from 'connected-components/CFLink';

import CFTitledComponent from 'components/CFTitledComponent';
import CFTitledSection, { InlineSubtitle } from 'components/CFTitledSection';
import Wizard from 'components/CFWizard';

import ModelCohort from '../components/cohort';
import ModelTarget from '../components/target';
import ModelRuns from '../components/runs';
import EmbeddingModelSummary from '../components/embeddingModel';
import ModelDefinitionView from '../components/definition';

import './model-censoring-details.scss';

interface Props {
  model: Model;
}

const CensoringDynamicFeatures = ({ ptrWindows }: { ptrWindows: PtrWithWindows[] }) => {
  return (
    <CFTitledComponent title="Dynamic features">
      {ptrWindows.map((ptrWindow) => {
        return (
          <div key={ptrWindow.ptr} className="trait-with-info">
            <CFTraitItem key={ptrWindow.ptr} addr={ptrWindow.ptr} />
            <CFWindowsInfo ptr={ptrWindow.ptr} windows={ptrWindow.windows} />
          </div>
        );
      })}
    </CFTitledComponent>
  );
};

const RDSMDynamicFeatures = ({ model }: { model: Model }) => {
  if (!model.definition.data_defn.dynamic_features) {
    return null;
  }

  return (
    <CFTitledComponent title="Dynamic features">
      {model.definition.data_defn.dynamic_features?.map((feat) => {
        const censoring = model.definition.data_defn.censoring;
        const transformations = censoring?.transformations;
        const transformation = transformations ? transformations[feat.ptr] : undefined;

        return (
          <div key={feat.ptr} className="trait-with-info">
            <CFTraitItem key={feat.ptr} addr={feat.ptr} />
            <span> ({(transformation || '').toUpperCase()}) </span>
          </div>
        );
      })}
    </CFTitledComponent>
  );
};

const ModelCensoringDetails = ({ model }: Props) => {
  const algorithm = model.definition.algo_spec.algo_name;
  const staticFeatures = model.definition.data_defn.static_features || [];

  return (
    <Wizard>
      <Wizard.Step name={Steps.Definition} ready={true}>
        <ModelDefinitionView />
      </Wizard.Step>

      <Wizard.Step name={Steps.UserCohort} ready={true}>
        <CFTitledSection title="Cohort" id={Steps.UserCohort}>
          <ModelCohort
            model={model}
            cohort={new Cohort(model.cohort)}
            splitRatio={model.definition.population_policy.split_ratio}
          />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Features} ready={true}>
        <CFTitledSection title="Features" id={Steps.Features}>
          <>
            {algorithm !== AlgorithmName.DeepRecurrentSurvivalMachines && (
              <CensoringDynamicFeatures ptrWindows={model.definition.data_defn.censoring?.dynamic_features || []} />
            )}

            {algorithm === AlgorithmName.DeepRecurrentSurvivalMachines && <RDSMDynamicFeatures model={model} />}

            {staticFeatures.length !== 0 && (
              <CFTitledComponent title="Static features">
                <div className="selected-items">
                  {staticFeatures.map((addr) => (
                    <div className="trait-with-info" key={getTraitNameFromAddr(addr)}>
                      <CFTraitItem addr={addr} />
                    </div>
                  ))}
                </div>
              </CFTitledComponent>
            )}
          </>
        </CFTitledSection>
      </Wizard.Step>

      {algorithm === AlgorithmName.DeepRecurrentSurvivalMachines && (
        <Wizard.Step name={Steps.EmbeddingModel} ready={true}>
          <CFTitledSection title="Embedding model" id={Steps.EmbeddingModel}>
            <InlineSubtitle>
              <CFLink to={`/model/${model.definition.data_defn.censoring?.embedding_model_id}/definition`} className="">
                {'#'}
                {model.definition.data_defn.censoring?.embedding_model_id}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
              </CFLink>
            </InlineSubtitle>
            <>
              {model.definition.data_defn.censoring?.embedding_model_id && (
                <EmbeddingModelSummary id={model.definition.data_defn.censoring.embedding_model_id.toString()} />
              )}
            </>
          </CFTitledSection>
        </Wizard.Step>
      )}

      <Wizard.Step name={Steps.Target} ready={true}>
        <CFTitledSection title="Target" id={Steps.Target}>
          <ModelTarget model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={'runs'} ready={true}>
        <CFTitledSection title="Runs" id={'runs'}>
          <ModelRuns model={model} />
        </CFTitledSection>
      </Wizard.Step>

      <Wizard.Step name={Steps.Schedule} ready={true}>
        <Schedule id={model.schedule.id} label="model" />
      </Wizard.Step>
    </Wizard>
  );
};

export default ModelCensoringDetails;
