import React from 'react';

import dayjs from 'dayjs';

import { AlgorithmSpec, AlgorithmType, InterventionId } from 'services/intervention/intervention.types';
import useIntervention from 'services/intervention/hooks/useIntervention';

import KeyValue from '../KeyValue';

import './intervention-detail.scss';

interface Props {
  id: InterventionId;
}

const InterventionDetail = ({ id }: Props) => {
  const intervention = useIntervention(id);

  return (
    <div className="intervention-detail">
      <div className="header"></div>

      <div className="content">
        <div className="description">
          <KeyValue
            name="Hypothesis description"
            value={intervention?.intervention.description || 'No description available'}
            forceBreakline={true}
          />
        </div>

        {intervention?.intervention.algo_policy?.type === AlgorithmType.Bandit && (
          <>
            <KeyValue
              name="Algorithm"
              value={(intervention?.intervention.algo_policy?.spec as AlgorithmSpec)?.algo_name || ''}
            />
            <KeyValue name="Model ID" value={intervention?.intervention.algo_policy?.model_def_id || ''} />
          </>
        )}
        <KeyValue name="Created by" value={intervention?.intervention.created_by || ''} />
        <KeyValue name="Created at" value={dayjs(intervention?.intervention.created_at).format('YYYY-MM-DD') || ''} />

        <KeyValue name="Cohort" value={intervention?.cohort.name || ''} />
        <KeyValue name="Sample size" value={intervention?.sampleSize as number} />

        <KeyValue
          name="Groups"
          value={Object.keys(intervention?.intervention.nudge_policy?.group_allocation || {}).length}
        />
        <KeyValue name="Status" value={intervention?.status || ''} />
      </div>
    </div>
  );
};

export default InterventionDetail;
