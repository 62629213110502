import React, { Children, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { ScatterSeriesOption } from 'echarts/charts';
import { getInstanceByDom } from 'echarts';

import colors from 'common.scss';

import { EChartsOption, ReactECharts } from '..';

import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

import { ScatterProps } from './types';
import { markPointLabel } from './chartElementHelper';

import '../charts.scss';
import './cf-scatter-plot-chart.scss';

const CFScatterPlotChart = ({
  title,
  description,
  series,
  xLabel,
  yLabel,
  splits = [],
  showSplit: split = false,
  showBoundingBoxes = false,
  showLegend = false,
  highlightEdgeValues = false,
  showStatLines = false,
  lastIsInfinite = false,
  className,
  nested = false,
  //step = 1,
  size = 500,
  square = false,
  overlapHeader = false,
  children,
}: ScatterProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const scatterX = splits.map((config, i, array) => {
    if (!split) {
      return;
    }

    return {
      type: 'scatter',
      data: [],
      animation: false,
      markArea: {
        silent: true,
        label: {
          show: true,
          position: 'right',
          color: config.labelColor,
          fontWeight: 'bold',
          fontSize: 13,
          distance: 10,
          textBorderColor: 'transparent',
        },
        itemStyle: {
          color: config.color,
          opacity: 0.2,
        },
        data: [
          [
            {
              name: config.label,
              yAxis: config.starts,
            },
            { yAxis: array[i + 1] ? array[i + 1].starts : 1 },
          ],
        ],
      },
      markLine: {
        silent: true,
        symbol: ['none', 'none'],
        lineStyle: {
          color: colors.dark40,
        },
        label: { show: false },
        data: [
          {
            name: 'split',
            yAxis: array[i + 1] ? array[i + 1].starts : 1,
          },
        ],
      },
    };
  });

  const scatterY = series.map((item) => {
    if (!item.area) {
      return;
    }

    return {
      type: 'scatter',
      data: [],
      animation: false,
      markArea: {
        silent: true,
        label: {
          position: 'top',
          color: item.color,
          fontWeight: 'bold',
          fontSize: 13,
          distance: 5,
          textBorderColor: 'transparent',
        },
        itemStyle: {
          color: 'transparent',
        },
        data: [
          [
            {
              name: item.area?.label,
              xAxis: item.area?.min,
            },
            { xAxis: item.area?.max },
          ],
        ],
      },
      markLine: {
        silent: true,
        symbol: ['none', 'none'],
        lineStyle: {
          color: colors.dark40,
        },
        label: { show: false },
        data: [
          {
            name: item.area?.label,
            xAxis: item.area?.max,
          },
        ],
      },
    };
  });

  const markAreaOptions = useMemo(() => {
    if (!showBoundingBoxes) {
      return null;
    }

    return {
      silent: true,
      itemStyle: {
        color: 'transparent',
        borderWidth: 1,
        borderType: 'dashed',
      },
      data: [
        [
          {
            xAxis: 'min',
            yAxis: 'min',
          },
          {
            xAxis: 'max',
            yAxis: 'max',
          },
        ],
      ],
    };
  }, [showBoundingBoxes]);

  const markPointOptions = useMemo(() => {
    if (!highlightEdgeValues) {
      return null;
    }

    return {
      animation: false,
      label: {
        show: true,
        formatter: function (params: any) {
          return Number(params.value).toFixed(3);
        },
      },
      data: [
        {
          type: 'max',
          symbol: 'square',
          symbolSize: 0,
          symbolOffset: [0, -30],
          backgroundColor: 'red',
          label: markPointLabel('Max'),
        },
        {
          type: 'max',
          symbol: 'triangle',
          symbolSize: [10, 10],
          symbolRotate: 180,
          symbolOffset: [0, -10],
          label: {
            show: false,
          },
        },
        {
          type: 'min',
          symbol: 'rect',
          symbolSize: 0,
          symbolOffset: [0, 30],
          padding: [20, 0, 0, 0],
          label: markPointLabel('Min'),
        },
        {
          type: 'min',
          symbol: 'triangle',
          symbolSize: [10, 10],
          symbolOffset: [0, 10],
          label: {
            show: false,
          },
        },
      ],
    };
  }, [highlightEdgeValues]);

  const markLineOptions = useMemo(() => {
    if (!showStatLines) {
      return null;
    }

    return {
      animation: false,
      lineStyle: {
        type: 'solid',
      },
      data: [
        {
          type: 'average',
          symbol: 'rect',
          name: 'AVG (X)',
          valueDim: 'x',
        },
        {
          type: 'average',
          name: 'AVG (Y)',
          label: {
            position: 'end',
            offset: [-30, 0],
          },
        },
      ],
      label: {
        show: true,
        formatter: function (params: any) {
          return `${params.name}\n${params.value}`;
        },
        position: 'end',
        borderColor: '#000',
        borderWidth: 1,
        borderRadius: 4,
        padding: [4, 6],
        backgroundColor: 'inherit',
        color: 'white',
      },
    };
  }, [showStatLines]);

  const allSeries = [
    ...scatterX,
    ...scatterY,
    ...series.map((seriesItem) => ({
      emphasis: {
        scale: false, // Desactiva el escalado en énfasis
        focus: 'none',
      },
      symbolSize: 3,
      itemStyle: {
        color: seriesItem.color,
      },
      name: seriesItem.name,
      type: 'scatter',
      data: seriesItem.values.map(({ x, y, id }) => [x, y, id]),

      markArea: markAreaOptions,
      markPoint: markPointOptions,
      markLine: markLineOptions,
      animation: false,
    })),
  ];

  const maxValueX = Math.max(...series.map((seriesItem) => seriesItem.values.map((value) => value.x)).flat()) * 1.2;
  const minValueX = Math.min(...series.map((seriesItem) => seriesItem.values.map((value) => value.x)).flat()) * 1.2;
  const maxValueY = Math.max(...series.map((seriesItem) => seriesItem.values.map((value) => value.y)).flat()) * 1.2;
  const minValueY = Math.min(...series.map((seriesItem) => seriesItem.values.map((value) => value.y)).flat()) * 1.2;

  const testOptions: EChartsOption = {
    grid: {
      left: '3%',
      right: '7%',
      bottom: '7%',
      containLabel: true,
    },
    tooltip: {
      showDelay: 0,
      show: false,
      axisPointer: {
        show: true,
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
      },
    },
    legend: {
      show: showLegend,
      top: 'top',
      right: '0px',
      textStyle: {
        color: '#fff',
        fontSize: 14,
      },
    },
    xAxis: [
      {
        axisPointer: {
          show: true,
          type: 'line',
          lineStyle: {
            type: 'dashed',
            width: 1,
          },
        },
        name: xLabel,
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        type: 'value',
        scale: true,
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (value: any) {
            if (lastIsInfinite && value >= maxValueX) {
              return '{a|∞}';
            } else {
              return parseFloat(value.toFixed(1)).toString();
            }
          },
          rich: {
            a: {
              fontSize: 16, // font style for infiniti symbol
              fontWeight: 'bold',
            },
          },
        },
        max: maxValueX,
        min: minValueX,
      },
    ],
    yAxis: [
      {
        axisPointer: {
          show: true,
          type: 'line',
          lineStyle: {
            type: 'dashed',
            width: 1,
          },
        },
        type: 'value',
        name: yLabel,
        nameLocation: 'middle',
        nameGap: 30,
        axisLabel: {
          formatter: function (value: any) {
            return parseFloat(value.toFixed(1)).toString();
          },
        },
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        scale: true,

        splitLine: {
          show: false,
        },
        max: maxValueY,
        min: minValueY,
      },
    ],
    series: allSeries as ScatterSeriesOption,
  };

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = getInstanceByDom(chartRef.current);

    if (!chart) {
      return;
    }

    chart?.on('legendselectchanged', function (params: any) {
      const selected = params.selected;
      const selectedItems = Object.values(selected).filter(Boolean);

      if (selectedItems.length === 0) {
        const lastSelected = params.name;
        chart.dispatchAction({
          type: 'legendSelect',
          name: lastSelected,
        });
      }
    });
  }, [chartRef]);

  const arrayChildren = Children.toArray(children);

  const chartUI = useMemo(() => {
    // this is to avoid rerender when the input value changes,
    // but we want to keep previuos points
    return (
      <ReactECharts
        ref={chartRef}
        option={testOptions}
        style={square ? { width: '100%', height: '100%' } : { width: '100%', height: size }}
      />
    );
  }, [testOptions, chartRef]);

  return (
    <div className={classnames('cf-scatter-chart', className, { nested: nested })}>
      <div className={classnames('chart-header', { overlap: overlapHeader })}>
        <div className="chart-header__title">
          <span>{title || ''} </span>
          {description && (
            <CFTooltip description={description} position={CFTooltipPositions.Right}>
              <FontAwesomeIcon icon={faCircleQuestion} size="xs" />
            </CFTooltip>
          )}
        </div>
        <div className="chart-header__controls">{arrayChildren}</div>
      </div>

      {chartUI}
    </div>
  );
};

export default CFScatterPlotChart;
