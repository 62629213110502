import React from 'react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { sessionQueryInfo } from 'hooks/useCFNavigation';

import './cf-link.scss';

interface Props {
  to: string;
  className: string;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const CFLink = ({ className, to, onClick, children }: Props) => {
  const query = sessionQueryInfo();
  const queryString = new URLSearchParams(query);

  return (
    <Link className={classNames('cf-link', className)} to={`${to}?${queryString.toString()}`} onClick={onClick}>
      {children}
    </Link>
  );
};

export default CFLink;
